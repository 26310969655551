<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="line-height: 30px">双摄追尾事故报警明细</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="alarmQuery" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号">
                            <el-input v-model="alarmQuery.condition.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="时间区间">
                            <el-date-picker v-model="alarmQuery.condition.timeRange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <el-table :data="alarmList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" width="150px" fixed />
                    <el-table-column label="车牌号" align="center" key="plateNo" prop="plateNo" />
                    <el-table-column label="VIN码" align="center" key="vin" prop="vin" />
                    <el-table-column label="告警时间" align="center" key="startTime" prop="startTime" />
                    <el-table-column label="告警地址" align="center" key="startLocation" prop="startLocation" />
                    <el-table-column label="告警类型" align="center" key="alarmType" prop="alarmType">
                  
                        <template slot-scope="scope">
                            <template v-if="scope.row.alarmType == 1 && scope.row.alarmCategory == 100"> 前车碰撞 </template>
                            <template v-else> 其他 </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="速度列表" align="center" key="speedList" prop="speedList"></el-table-column>

                    <el-table-column label="查看附件" align="center">
                        <template slot-scope="scope">
                            <el-link @click="showImage(scope.row)" type="primary">预览</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="alarmQuery.pageNo" :limit.sync="alarmQuery.pageSize" @pagination="fetchAlarmList" />
            </div>

            <!-- 附件弹窗 -->
            <el-dialog class="attachment_dialog" :visible.sync="dialogVisible" width="1000px" :modal-append-to-body="false">
                <div v-loading="previewLoading">
                    <div class="dialog_box" v-if="imgList.length > 0">
                        <div class="dialog_title">
                            <span>{{ attachmentRow.deviceNumber ? attachmentRow.deviceNumber : '--' }}</span>
                            <span class="warning_type" v-if="attachmentRow.alarmType == 1 && attachmentRow.alarmCategory == 100">前车碰撞</span>
                            <span class="warning_type" v-else>其他</span>
                            <span>{{ attachmentRow.startTime ? attachmentRow.startTime : '--' }}</span>
                        </div>
                        <div class="attachment_box clearfix">
                            <div class="box_left fl" v-if="dialogVisible">
                                <img-deal ref="imgDeal" :url="currentItem.ossPath" v-if="currentItem.isImg"></img-deal>
                                <video :src="currentItem.ossPath" controls autoplay muted loop v-else></video>
                            </div>
                            <ul class="box_right fr">
                                <li :class="{ subsetActive: activeImgIndex == imgIndex, Img_li: 1 }" v-for="(imgItem, imgIndex) in imgList" :key="imgIndex" @click="previewItem(imgItem, imgIndex)">
                                    <img :src="imgItem.ossPath" v-if="imgItem.isImg" />
                                    <video :src="currentItem.ossPath" :poster="imgList[0].ossPath" muted v-else></video>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <el-empty description="暂无数据" v-else></el-empty>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
import ImgDeal from '@/components/ImgDeal.vue';

export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            currentItem: {},
            activeImgIndex: 0,
            attachmentRow: {},
            dialogVisible: false,
            previewLoading: false,
            imgList: [],
            subsetIndex: 0,
            loading: false,
            wineCheckFlag: false,
            showCaseList: [],
            identifyLoading: false,
            initialIndex: 0,
            imgDialogVisible: false,
            imgUrlList: [],
            audioTypeList: ['mp3', 'mp4', 'wav', 'wma', 'aac', 'flac', 'ogg'],
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'],
            alarmQuery: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },
            alarmList: [],
            total: 0,
            alarmLoading: false
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.fetchAlarmList();
    },
    methods: {
        showImage(row) {
            console.log(row);

            this.attachmentRow = row;
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            this.instance.get('/operate/api/collisionAlarmInfo/attachments?alarmUploadId=' + row.alarmUploadId).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.imgList = [];
                    let tmpItem = {};
                    data.data.forEach((item) => {
                        let fileType = item.split('.').pop().split('?')[0];
                        tmpItem = {
                            ossPath: item,
                            fileType: fileType,
                            isImg: fileTypeList.indexOf(fileType) != -1 ? true : false
                        };

                        this.imgList.push(tmpItem);
                    });

                    this.previewItem(this.imgList[0], 0);
                } else {
                    this.$message.error('暂无数据！');
                }
            });
        },
        previewItem(imgItem, imgIndex) {
            this.activeImgIndex = imgIndex;
            this.currentItem = imgItem;
            this.dialogVisible = true;
        },
        fetchAlarmList() {
            this.alarmLoading = true;

            let data = JSON.parse(JSON.stringify(this.alarmQuery));
            if (data.condition.timeRange) {
                data.condition.startTime = data.condition.timeRange[0] + ' 00:00:00';
                data.condition.endTime = data.condition.timeRange[1] + ' 23:59:59';
                data.condition.timeRange = null;
            }
            this.instance.post('/operate/api/collisionAlarmInfo/paging', data).then((res) => {
                this.alarmLoading = false;
                this.alarmList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        handleQuery() {
            this.fetchAlarmList();
        },
        resetQuery() {
            this.alarmQuery = {
                condition: {},
                pageNo: 1,
                pageSize: 10
            };
            this.fetchAlarmList();
        },
        analyzeSuffix(fileUrl) {
            // 以?分割字符串
            let parts = fileUrl.split('?');
            // 获取?前面的部分
            let partBeforeQuestionMark = parts[0];
            // 以/分割?前面的部分
            let subParts = partBeforeQuestionMark.split('/');
            // 获取最后一个/后的内容
            let lastPartBeforeQuestionMark = subParts[subParts.length - 1];
            // 以.分割最后一个/后的内容
            let extensionParts = lastPartBeforeQuestionMark.split('.');
            // 获取最后一个.后的字符串
            let fileExtension = extensionParts[extensionParts.length - 1];
            return fileExtension;
        },
        previewImg(src, i) {
            this.previewUrl = src;
            this.imgActiveIndex = i;
            this.imgDialogVisible = true;
            this.subsetIndex = i;
            this.tmpPreviewIndex = i;
            this.initialIndex = i;
            // 右侧案件列表标题
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(i);
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1;
            this.subsetIndex = rowIndex;
            // 当前图片index
            this.tmpPreviewIndex = rowIndex;
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1);
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1);
                }
            } else {
                this.$refs.carouselRef.prev();
                this.initialIndex--;
            }
            this.switchFlag = true;
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(null, 0, this.showCaseList[0], 0);
                } else {
                    this.subsetChange(null, 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1);
                }
            } else {
                this.$refs.carouselRef.next();
                this.initialIndex++;
            }
            this.switchFlag = true;
        },
        subsetChange(items, itemsIndex, item, index) {
            this.subsetIndex = itemsIndex;
            this.thumbnailActiveIndex = index;
            this.switchCarousel(item, index);
            this.initialIndex = itemsIndex;
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(itemsIndex);
        },
        switchCarousel(data, index) {
            this.imgDistance = 0;
            this.imgActiveIndex = 0;
            this.liActiveIndex = index;
            this.titleIndex = 1;
            this.thumbnailActiveIndex = index;

            if (data && data.files && data.files.length > 0) {
                this.imgUrlList = data.files;
            }
        },
        closeImgDialog() {
            this.imgDialogVisible = false;
            this.wineCheckFlag = false;
            this.identifyLoading = false;
            this.checkedForm = {
                wineType: '',
                materialId: '',
                code: ''
            };
            this.queryWineFlag && this.$emit('getCaseDetail');
            this.queryWineFlag = false;
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
}

.card_content {
    padding-bottom: 20px;
    .content_row {
        .col_title {
            text-align: right;
        }
        .col_txt {
            line-height: 22px;
            .select_user {
                margin-left: 10px;
                margin-block: 5px;
            }
        }
    }

    .tab_content {
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #fff;
        }
        .noneTableData::before {
            height: 0px !important;
        }
        .content_row {
            margin-bottom: 25px;
            .project_row {
                display: flex;
                align-items: center;
            }
            .dy_row {
                // position: relative;
                .col_title {
                    position: absolute;
                    top: 5px;
                }
                // .col_txt {
                //     position: absolute;
                //     right: 0;
                //     top: 0;
                // }
            }
            .situation_box {
                padding: 20px;
                height: 300px;
                overflow-y: scroll;
                border: 1px solid #dcdcdc;
                .situation_item {
                    margin-bottom: 15px;
                }
                .situation_item:last-child {
                    margin-bottom: 0;
                }
            }
            .txt_box {
                display: inline-block;
            }
            .click_txt {
                margin-left: 20px;
                color: #409eff;
                cursor: pointer;
                border-bottom: 1px solid #409eff;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .info_input {
                width: 80%;
            }
            .align_col {
                display: flex;
                align-items: top;
            }
        }
        .btn_group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        // 编辑
        .edit_content {
            .content_row {
                display: flex;
                align-items: center;
            }
            .exception_row {
                display: inline !important;
            }
        }
        .preview_file {
            position: relative;
            xc span {
                cursor: pointer;
                color: #409eff;
            }
            .preview_image {
                // width: 100%;
                // height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                /deep/ img {
                    opacity: 0 !important;
                }
            }
        }
    }
}
.preview_dialog {
    .preview_content {
        .el-row {
            margin-bottom: 10px;
        }
    }
    .preview_audio {
        margin-top: 20px;
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        audio {
            width: 100%;
            height: 30px;
        }
    }
    .right_table {
        max-height: 300px;
    }
    .case_list {
        .el-tabs--top {
            position: relative;
            z-index: 2;
            background: #fff;
        }
        .right_table {
            margin-top: -43px;
            position: relative;
            z-index: 1;
        }
    }
    .suspendContent {
        position: relative;
        .modelBtn {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 50px;
            right: 100px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #f85008;
            z-index: 8;
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
                height: 20px;
            }
        }
        .suspend_box {
            position: absolute;
            width: 250px;
            height: 150px;
            z-index: 10;
            background: #fff;
            border-radius: 2px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
            left: 483px;
            top: 445px;
            .suspend_head {
                position: relative;
                padding: 10px;
                cursor: move;
                .closeBtn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                }
            }
            .suspend_content {
                padding: 10px;
                padding-top: 0px;
                max-height: 110px;
                overflow-y: auto;
                p {
                    line-height: 22px;
                }
                .foldTxt {
                    font-weight: 600;
                }
            }
        }
    }
}

.upload_dialog {
    .dialog_content {
        .upload_btn {
            text-align: center;
        }
        .files_box {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .del_icon {
                cursor: pointer;
                padding: 4px;
            }
        }
    }
}

.attachment_dialog {
    /deep/ .el-dialog__body {
        padding-top: 0;
    }
    .dialog_title {
        span {
            color: #333;
            font-size: 16px;
        }
        .warning_type {
            margin: 0 30px;
        }
    }
    .attachment_box {
        height: 550px;
        .box_left {
            width: 780px;
            height: 550px;
            padding-right: 40px;
            border-right: 1px solid #8b8b8b;
            video {
                width: 100%;
                height: 100%;
                // border: 1px solid #ccc;
            }
        }
        .box_right {
            width: 170px;
            height: 550px;
            overflow-y: auto;
            // margin-right: 20px;
            text-align: center;
            padding-left: 25px;
            li {
                width: 120px;
                height: 120px;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            li:last-child {
                margin-bottom: 0;
            }
            .subsetActive {
                border: 2px solid red !important;
            }
        }
    }
}
</style>