<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="line-height: 30px">双摄问题设备管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号">
                            <el-input v-model="queryParams.condition.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="问题类型">
                            <el-select v-model="queryParams.condition.type" placeholder="请选择问题类型" clearable>
                                <el-option label="全部" value="0" key="0"></el-option>
                                <el-option label="无DMS告警" value="1" key="1"></el-option>
                                <el-option label="标定失败" value="2" key="2"></el-option>
                                <el-option label="镜头遮挡" value="3" key="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="时间区间">
                            <el-date-picker v-model="queryParams.condition.timeRange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <el-table :data="recordList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" width="150px" fixed>
                        <template slot-scope="scope">
                            <el-link @click="realTimeVideo(scope.row)" type="primary">{{ scope.row.deviceNumber }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="车牌号" align="center" key="plateNo" prop="plateNo" />
                    <el-table-column label="VIN码" align="center" key="vin" prop="vin" />
                    <el-table-column label="时间" align="center" key="reportTime" prop="reportTime" />
                    <el-table-column label="告警数量" align="center" key="allAlarmCount" prop="allAlarmCount" />
                    <el-table-column label="ADAS告警数量" align="center" key="adasCount" prop="adasCount" />
                    <el-table-column label="DMS告警数量" align="center" key="dmsCount" prop="dmsCount" />
                    <el-table-column label="标定失败告警数量" align="center" key="biaoDingShiBaiCount" prop="biaoDingShiBaiCount">
                        <template slot-scope="scope">
                            {{ scope.row.biaoDingShiBaiCount }}
                            <template v-if="scope.row.biaoDingShiBaiCount > 0"> | <el-link @click="showImage(scope.row, 1)" type="primary">预览</el-link> </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="镜头遮挡告警数量" align="center" key="jingTouZheDangCount" prop="jingTouZheDangCount">
                        <template slot-scope="scope">
                            {{ scope.row.jingTouZheDangCount }}
                            <template v-if="scope.row.jingTouZheDangCount > 0"> | <el-link @click="showImage(scope.row, 2)" type="primary">预览</el-link> </template>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchRecordList" />
            </div>
            <!-- 图片预览 弹窗 -->
            <el-dialog :class="{ preview_dialog: 1, preview_artificial: $route.path == '/device/devicesExpired' }" :visible.sync="imgDialogVisible" center append-to-body width="1066px" :before-close="closeImgDialog">
                <div class="zhanwei" style="height: 15px; width: 10px"></div>
                <div :class="wineCheckFlag ? 'dialog_content wine_content  clearfix' : 'dialog_content suspendContent clearfix'">
                    <div class="content_left fl">
                        <el-carousel
                            class="content_carousel"
                            ref="carouselRef"
                            trigger="click"
                            arrow="never"
                            :autoplay="false"
                            :initial-index="initialIndex"
                            indicator-position="none"
                            @change="carouselChange"
                            v-loading="identifyLoading"
                            element-loading-text="识别中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)"
                        >
                            <el-carousel-item v-for="(imgUrl, imgIndex) in imgUrlList" :key="imgIndex">
                                <img-deal :id="'imgDeal' + imgIndex" ref="imgDeal" :url="imgUrl.url" :wineCheckFlag="wineCheckFlag"></img-deal>
                            </el-carousel-item>
                            <el-button class="carousel_btn carousel_left" type="info" icon="el-icon-arrow-left" circle @click="previewPrev"></el-button>
                            <el-button class="carousel_btn carousel_right" type="info" icon="el-icon-arrow-right" circle @click="previewNext"></el-button>
                        </el-carousel>
                    </div>
                    <div class="content_right fr">
                        <ul :class="imgUrlList[initialIndex] && imgUrlList[initialIndex].repeatCaseNos && imgUrlList[initialIndex].repeatCaseNos.length > 1 ? 'types_ul-over types_ul clearfix' : 'types_ul clearfix'">
                            <template v-for="(item, index) in showCaseList">
                                <li :key="index">
                                    <p class="thumbnail_title">{{ item ? item.parentName : '' }}（{{ item && item.files ? item.files.length : '0' }}）</p>
                                    <ul :class="thumbnailActiveIndex == index ? 'thumbnail_ul thumbnailActive clearfix' : 'thumbnail_ul clearfix'">
                                        <template v-if="item.files && item.files.length > 0">
                                            <!-- 右侧缩略图 -->
                                            <li :class="subsetIndex == itemsIndex && thumbnailActiveIndex == index ? 'subsetActive Img_li fl' : 'Img_li fl'" v-for="(items, itemsIndex) in item.files" :key="itemsIndex" @click="subsetChange(items, itemsIndex, item, index)">
                                                <!-- 图片样式 -->
                                                <template v-if="items.isImg && items.url">
                                                    <img :src="items.url" />
                                                </template>
                                                <!-- 非图片后缀样式 -->
                                                <template v-else-if="items.url">
                                                    <div class="img_none">
                                                        <p style="margin: 4px 0">{{ items.suffix }}</p>
                                                        <p>!&nbsp;非图片文件</p>
                                                        <p style="margin-top: 5px">无法展示</p>
                                                    </div>
                                                </template>
                                                <div class="imgModel" v-if="items.isModel">模型分析</div>
                                            </li>
                                        </template>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
import ImgDeal from '@/components/ImgDeal.vue'
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            loading: false,
            showPopup: false,
            previewShow: false,
            imgDialogVisible: false,
            identifyLoading: false,
            wineCheckFlag: false,
            imgActiveIndex: 0,
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            initialIndex: 0,
            liActiveIndex: 0,
            thumbnailActiveIndex: 0,
            subsetIndex: 0,
            showCaseListRealityLength: 0,
            showCaseList: [],
            imgUrlList: [],
            audioTypeList: ['mp3', 'mp4', 'wav', 'wma', 'aac', 'flac', 'ogg'],
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'],
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },
            recordList: [],
            imageVisible: false,
            imageUrl: '',
            title: '',
            total: 0
        }
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL
    },
    created() {
        //this.fetchCompanyList();
        this.fetchRecordList()
    },
    methods: {
        fetchRecordList() {
            this.loading = true

            let data = JSON.parse(JSON.stringify(this.queryParams))

            this.instance.post('/operate/api/dualCamera/fetchProblemDevice', data).then((res) => {
                this.loading = false
                this.recordList = res.data.data.list
                this.total = parseInt(res.data.data.total)
            })
        },
        realTimeVideo(deviceRow) {
            let urlStr = `https://evcard.sigreal.cn/vehicleMap?from=devices&devices=true&deviceNumber=${deviceRow.deviceNumber}&rowStartTime=null&deviceType=1&queryData=%7B%22condition%22%3A%7B%22search%22%3Anull,%22companyId%22%3Anull,%22onOffLineStatus%22%3Anull,%22bindStatus%22%3Anull,%22activationStatus%22%3Anull,%22fleetId%22%3Anull%7D,%22orders%22%3A%5B%5D,%22pageNo%22%3A1,%22pageSize%22%3A10,%22pageTotal%22%3A103%7D`
            window.open(urlStr, '_blank')
        },
        showImage(row, type) {
            let data = {
                deviceNumber: row.deviceNumber,
                reportTime: row.reportTime
            }
            this.instance.post('/operate/api/dualCamera/getAdvanceAlarmImagePath?type=' + type, data).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data) {
                    this.imgDialogVisible = true
                    this.initMaterialsList(data.data)
                } else {
                    this.$message.error('暂无数据！')
                }
            })
        },
        handleQuery() {
            this.queryParams.pageNo = 1
            this.fetchRecordList()
        },
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            }
        },
        reset() {
            this.resetQuery()
        },
        resetWithLoadRecord() {
            this.reset()
            this.fetchRecordList()
        },
        analyzeSuffix(fileUrl) {
            // 以?分割字符串
            let parts = fileUrl.split('?')
            // 获取?前面的部分
            let partBeforeQuestionMark = parts[0]
            // 以/分割?前面的部分
            let subParts = partBeforeQuestionMark.split('/')
            // 获取最后一个/后的内容
            let lastPartBeforeQuestionMark = subParts[subParts.length - 1]
            // 以.分割最后一个/后的内容
            let extensionParts = lastPartBeforeQuestionMark.split('.')
            // 获取最后一个.后的字符串
            let fileExtension = extensionParts[extensionParts.length - 1]
            return fileExtension
        },
        initMaterialsList(materialsList) {
            this.showCaseList = []
            let tmpMaterials = JSON.parse(JSON.stringify(materialsList))
            let listData = []
            tmpMaterials.forEach((item) => {
                // 材料预览目前只展示图片
                if (this.analyzeSuffix(item) != 'mp4') {
                    let tmpItem = {
                        suffix: this.analyzeSuffix(item),
                        isImg: this.analyzeSuffix(item) == 'mp4' ? false : true,
                        url: item
                    }
                    listData.push(tmpItem)
                }
            })

            // 用于存储分类结果的对象
            let classification = {}

            // 遍历数组 a 进行分类
            listData.forEach((item) => {
                const suffix = item.suffix
                if (!classification[suffix]) {
                    classification[suffix] = []
                }
                classification[suffix].push(item)
            })

            // 将分类结果转换为所需的新数组格式
            this.showCaseList = Object.keys(classification).map((suffix) => ({
                parentName: suffix == 'mp4' ? '视频' : '图片',
                files: classification[suffix]
            }))

            if (this.showCaseList.length > 0) {
                this.switchCarousel(this.showCaseList[0], 0)
                this.liActiveIndex = 0
            }
        },
        previewImg(src, i) {
            this.previewUrl = src
            this.imgActiveIndex = i
            this.imgDialogVisible = true
            this.subsetIndex = i
            this.tmpPreviewIndex = i
            this.initialIndex = i
            // 右侧案件列表标题
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(i)
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1
            this.subsetIndex = rowIndex
            // 当前图片index
            this.tmpPreviewIndex = rowIndex
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1)
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1)
                }
            } else {
                this.$refs.carouselRef.prev()
                this.initialIndex--
            }
            this.switchFlag = true
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(null, 0, this.showCaseList[0], 0)
                } else {
                    this.subsetChange(null, 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1)
                }
            } else {
                this.$refs.carouselRef.next()
                this.initialIndex++
            }
            this.switchFlag = true
        },
        subsetChange(items, itemsIndex, item, index) {
            this.subsetIndex = itemsIndex
            this.thumbnailActiveIndex = index
            this.switchCarousel(item, index)
            this.initialIndex = itemsIndex
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(itemsIndex)
        },
        switchCarousel(data, index) {
            this.imgDistance = 0
            this.imgActiveIndex = 0
            this.liActiveIndex = index
            this.titleIndex = 1
            this.thumbnailActiveIndex = index

            if (data && data.files && data.files.length > 0) {
                this.imgUrlList = data.files
            }
        },
        closeImgDialog() {
            this.imgDialogVisible = false
            this.wineCheckFlag = false
            this.identifyLoading = false
            this.checkedForm = {
                wineType: '',
                materialId: '',
                code: ''
            }
            this.queryWineFlag && this.$emit('getCaseDetail')
            this.queryWineFlag = false
        }
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
}

.card_content {
    padding-bottom: 20px;
    .content_row {
        .col_title {
            text-align: right;
        }
        .col_txt {
            line-height: 22px;
            .select_user {
                margin-left: 10px;
                margin-block: 5px;
            }
        }
    }

    .tab_content {
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #fff;
        }
        .noneTableData::before {
            height: 0px !important;
        }
        .content_row {
            margin-bottom: 25px;
            .project_row {
                display: flex;
                align-items: center;
            }
            .dy_row {
                // position: relative;
                .col_title {
                    position: absolute;
                    top: 5px;
                }
                // .col_txt {
                //     position: absolute;
                //     right: 0;
                //     top: 0;
                // }
            }
            .situation_box {
                padding: 20px;
                height: 300px;
                overflow-y: scroll;
                border: 1px solid #dcdcdc;
                .situation_item {
                    margin-bottom: 15px;
                }
                .situation_item:last-child {
                    margin-bottom: 0;
                }
            }
            .txt_box {
                display: inline-block;
            }
            .click_txt {
                margin-left: 20px;
                color: #409eff;
                cursor: pointer;
                border-bottom: 1px solid #409eff;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .info_input {
                width: 80%;
            }
            .align_col {
                display: flex;
                align-items: top;
            }
        }
        .btn_group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        // 编辑
        .edit_content {
            .content_row {
                display: flex;
                align-items: center;
            }
            .exception_row {
                display: inline !important;
            }
        }
        .preview_file {
            position: relative;
            xc span {
                cursor: pointer;
                color: #409eff;
            }
            .preview_image {
                // width: 100%;
                // height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                /deep/ img {
                    opacity: 0 !important;
                }
            }
        }
    }
}
.preview_dialog {
    .preview_content {
        .el-row {
            margin-bottom: 10px;
        }
    }
    .preview_audio {
        margin-top: 20px;
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        audio {
            width: 100%;
            height: 30px;
        }
    }
    .right_table {
        max-height: 300px;
    }
    .case_list {
        .el-tabs--top {
            position: relative;
            z-index: 2;
            background: #fff;
        }
        .right_table {
            margin-top: -43px;
            position: relative;
            z-index: 1;
        }
    }
    .suspendContent {
        position: relative;
        .modelBtn {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 50px;
            right: 100px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #f85008;
            z-index: 8;
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
                height: 20px;
            }
        }
        .suspend_box {
            position: absolute;
            width: 250px;
            height: 150px;
            z-index: 10;
            background: #fff;
            border-radius: 2px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
            left: 483px;
            top: 445px;
            .suspend_head {
                position: relative;
                padding: 10px;
                cursor: move;
                .closeBtn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                }
            }
            .suspend_content {
                padding: 10px;
                padding-top: 0px;
                max-height: 110px;
                overflow-y: auto;
                p {
                    line-height: 22px;
                }
                .foldTxt {
                    font-weight: 600;
                }
            }
        }
    }
}

.upload_dialog {
    .dialog_content {
        .upload_btn {
            text-align: center;
        }
        .files_box {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .del_icon {
                cursor: pointer;
                padding: 4px;
            }
        }
    }
}
</style>