<template>
    <div class="app-container" v-loading="loading">
        <el-card>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号/车牌号/VIN">
                            <el-input v-model="queryParams.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="筛选日期">
                            <el-date-picker v-model="dataRange" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
        </el-card>
        <br />
        <el-card v-if="lossMileageInfo" style="font-size: 14px">
            <el-table :data="lossMileageInfo">
                <el-table-column label="设备号" key="deviceNumber" prop="deviceNumber"> </el-table-column>
                <el-table-column label="缺失里程" key="lossMileageDriving" prop="lossMileageDriving"> </el-table-column>
                <el-table-column label="有效里程" key="validMileage" prop="validMileage"> </el-table-column>
                <el-table-column label="缺失率" key="lossRatio" prop="lossRatio">
                    <template slot-scope="scope"> {{ scope.row.lossRatio }}% </template>
                </el-table-column>
            </el-table>
        </el-card>
        <br />
        <el-card>
            <div style="float: right; line-height: 50px">
                <el-button @click="downloadPoints" icon="el-icon-download" type="primary" size="mini">下载点位数据</el-button>
            </div>
            <div id="map_view" style="width: 100%; height: 500px"></div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'obdDataSearch',
    data() {
        return {
            loading: false,
            queryParams: {
                deviceNumber: null
            },
            dataRange: [],
            pointList: [],
            total: 0,
            lossMileageInfo: null,
            baseURL: ''
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        this.map = new BMapGL.Map('map_view');
        // 创建地图实例

        // 设置地图中心点坐标和缩放级别
        var point = new BMapGL.Point(116.404, 39.915); // 北京市中心
        this.map.centerAndZoom(point, 15); // 设置中心点坐标和缩放级别

        var top_left_control = new BMapGL.ScaleControl(); // 左上角，添加比例尺
        var top_left_navigation = new BMapGL.NavigationControl(); //左上角，添加默认缩放平移控件
        var top_right_navigation = new BMapGL.NavigationControl(); //右上角，仅包含平移和缩放按钮
        this.map.addControl(top_left_control);
        this.map.addControl(top_left_navigation);
        this.map.addControl(top_right_navigation);

        //this.map.disableAutoResize();
        //this.map.enableDragging(false);
    },
    methods: {
        formattedTime(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hour = ('0' + date.getHours()).slice(-2);
            const minute = ('0' + date.getMinutes()).slice(-2);
            const second = ('0' + date.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        },
        fetchLossMileageRatio() {
            const path = `/operate/api/loss_mileage_ratio?deviceNumber=${this.queryParams.deviceNumber}&startTime=${this.queryParams.startTime}&endTime=${this.queryParams.endTime}`;
            this.instance.get(path).then((res) => {
                this.lossMileageInfo = [res.data.data];
            });
        },
        fetchPointList() {
            if (!this.queryParams.deviceNumber) {
                this.$message({ message: '请输入设备号进行查询！', type: 'error' });
                return;
            }

            if (this.dataRange.length == 2) {
                if (this.dataRange[1]) this.queryParams.endTime = this.dataRange[1];
                if (this.dataRange[0]) this.queryParams.startTime = this.dataRange[0];
            } else {
                this.$message({ message: '请输入起始时间进行查询！', type: 'error' });
                return;
            }
            this.loading = true;
            const path = `/operate/api/point/list?deviceNumber=${this.queryParams.deviceNumber}&startTime=${this.queryParams.startTime}&endTime=${this.queryParams.endTime}`;
            this.instance.get(path).then((res) => {
                this.loading = false;
                this.pointList = res.data.data;
                this.total = this.pointList.length;
                this.renderMapView();
            });
        },
        renderMapView() {
            var mp = this.map;
            mp.clearOverlays();
            mp.centerAndZoom(new BMapGL.Point(116.404, 39.915), 15); // 设置中心点坐标和缩放级别
            if (this.total == 0) {
                this.$message.warn('未查询到任何GPS点');
                return;
            }
            const dotStyle = {
                size: 6,
                shape: 'BMAP_SHAPE_CIRCLE',
                fillColor: 'red'
            };
            var maxLon = 0;
            var minLon = 999999;
            var maxLat = 0;
            var minLat = 999999;
            const baiduPoints = new Array();
            for (var i = 0; i < this.pointList.length; i++) {
                const point = this.pointList[i];

                var lon = point.lon,
                    lat = point.lat;

                if (lon < 70 || lon > 140) continue;
                if (lat < 2 || lat > 60) continue;
                if (point.sp == 0) continue;
                if (lon > maxLon) maxLon = lon;
                if (lon < minLon) minLon = lon;
                if (lat > maxLat) maxLat = lat;
                if (lat < minLat) minLat = lat;

                const bdPoint = new BMapGL.Point(lon, lat);
                baiduPoints.push(bdPoint);
                var pointColor = ""; // 点的颜色
                var pointSize=6;
                var pointText="";
              // 根据点位类型设置点的颜色
                switch (point.pt) {
                  case 311:
                   pointColor = "#f50add";
                   pointSize =30;
                   pointText="急加速";
                   break;
                  case 312:
                   pointColor = "#0a7fe5";
                   pointSize =30;
                   pointText="急减速";
                   break;
                  case 319:
                   pointColor = "#0af574";
                   pointSize =30;
                   pointText="急转弯";
                   break;
                  case 14:
                   pointColor = "#f5700a";
                   pointSize =30;
                   pointText="超速";
                   break;
                 default:
                   pointColor = "red";
                   pointSize =6;
              }
               const dotStyle = {
                 size: pointSize,
                 shape: 'BMAP_SHAPE_CIRCLE',
                 fillColor: pointColor
               };
                const pointMark = new BMapGL.Marker3D(bdPoint, 0, dotStyle);
                const content = `时间：${this.formattedTime(point.st)}<br>卫星数：${point.sc}<br>速度：${point.sp.toFixed(2)}<br>点位类别：${pointText}`;

                const label = new BMapGL.Label(content, {
                    // 创建文本标注
                    position: bdPoint // 设置标注的地理位置
                });
                label.setStyle({
                    // 设置label的样式
                    color: '#000',
                    fontSize: '14px',
                    border: '2px solid #1E90FF'
                });
                pointMark.addEventListener('mouseover', () => {
                    mp.addOverlay(label);
                });
                pointMark.addEventListener('mouseout', () => {
                    mp.removeOverlay(label);
                });
                mp.addOverlay(pointMark);
            }
            //mp.centerAndZoom(new BMapGL.Point(points[0][0], points[0][1]), 15);
            mp.setViewport([new BMapGL.Point(maxLon, maxLat), new BMapGL.Point(maxLon, minLat), new BMapGL.Point(minLon, maxLat), new BMapGL.Point(minLon, minLat)]);

            var polyline = new BMapGL.Polyline(baiduPoints, { strokeColor: 'green', strokeWeight: 3 });
            mp.addOverlay(polyline);
            mp.addOverlay(new BMapGL.Marker3D(baiduPoints[0], 0, { ...dotStyle, fillColor: 'blue', size: 10 }));
            mp.addOverlay(new BMapGL.Label('起点', { position: baiduPoints[0] }));
            mp.addOverlay(new BMapGL.Marker3D(baiduPoints[baiduPoints.length - 1], 0, { ...dotStyle, fillColor: 'yellow', size: 10 }));
            mp.addOverlay(new BMapGL.Label('终点', { position: baiduPoints[baiduPoints.length - 1] }));
        },
        downloadPoints() {
            const path = this.baseURL + `/operate/api/point/download?deviceNumber=${this.queryParams.deviceNumber}&startTime=${this.queryParams.startTime}&endTime=${this.queryParams.endTime}`;
            window.open(path, '_blank');
        },
        handleQuery() {
            this.fetchPointList();
            this.fetchLossMileageRatio();
        },
        resetQuery() {
            this.queryParams = {};
            this.dataRange = [];
        },
        reset() {
            this.resetQuery();
            this.pointList = [];
        }
    }
};
</script>