<template>
    <div class="innerPage wrap">
        <div class="page_title"><b>事故车辆数据查询</b></div>
        <div class="public_box">
            <el-row :gutter="40" style="margin-left: 10px">
                <el-form :model="queryFrom" ref="queryForm" size="small" :inline="true">
                    <el-form-item label="车牌号：">
                        <el-input v-model="queryFrom.plateNo" placeholder="请输入车牌号" clearable />
                    </el-form-item>
                    <el-form-item label="出险时间">
                        <el-date-picker v-model="queryFrom.accidentTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"> </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="searchData">查 询</el-button>
                        <el-button type="primary" size="mini">生成报告</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </div>

        <el-tabs v-model="activeName" @tab-click="tabClick">
            <el-tab-pane label="车辆数据" name="first"></el-tab-pane>
            <el-tab-pane label="本地视频" name="second"></el-tab-pane>
        </el-tabs>

        <div class="tab-pane" v-show="activeName == 'first'">
            <div class="result_box case_card" v-if="resultData" v-loading="searchFlag">
                <div class="case_row">
                    <div class="card_title fold_title">
                        <i :class="isFold0 ? 'el-icon-arrow-up fold_icon' : 'el-icon-arrow-down fold_icon'" @click="isFold0 = !isFold0"></i>
                        <span>车辆信息</span>
                    </div>
                    <div class="card_content text_columns clearfix" v-if="isFold0">
                        <div class="speed_box" v-if="resultData.deviceInfo">
                            <el-row class="contentRow">
                                <el-col class="contentTxt" :span="5" :offset="1">设备号：{{ resultData.deviceInfo.deviceNumber ? resultData.deviceInfo.deviceNumber : '--' }}</el-col>
                                <el-col class="contentTxt" :span="5" :offset="1">车队：{{ resultData.deviceInfo.tenantName ? resultData.deviceInfo.tenantName : '--' }}</el-col>
                            </el-row>
                        </div>
                        <p class="noneData" v-else>暂无数据</p>
                    </div>
                </div>
                <div class="case_row">
                    <div class="card_title fold_title">
                        <i :class="isFold1 ? 'el-icon-arrow-up fold_icon' : 'el-icon-arrow-down fold_icon'" @click="isFold1 = !isFold1"></i>
                        <span>碰撞前后 30 秒车速</span>
                    </div>
                    <div class="card_content text_columns clearfix" v-if="isFold1">
                        <div class="speed_box" v-if="resultData.pointVos && resultData.pointVos.length > 0">
                            <template v-for="(item, index) in resultData.pointVos">
                                <el-row :class="{ contentRow: 1, tipTxtRed: item.time == queryFrom.accidentTime }" :key="index">
                                    <el-col class="contentTxt" :span="5" :offset="1">时间：{{ item.time ? item.time : '--' }}</el-col>
                                    <el-col class="contentTxt" :span="4" :offset="1">速度：{{ String(item.speed) != 'null' ? item.speed : '--' }} km/h</el-col>
                                    <el-col class="contentTxt" :span="10" :offset="1">经纬度：{{ item.longitude ? item.longitude : '--' }},{{ item.latitude ? item.latitude : '--' }}</el-col>
                                </el-row>
                                <el-row :class="{ contentRow: 1, addressStyle: 1, tipTxtRed: item.time == queryFrom.accidentTime }" :key="index + 10086">
                                    <el-col class="contentTxt" :span="23" :offset="1">地址：{{ item.geoVo && item.geoVo.result && item.geoVo.result.formatted_address ? item.geoVo.result.formatted_address : '--' }}</el-col>
                                </el-row>
                            </template>
                        </div>
                        <p class="noneData" v-else>暂无数据</p>
                    </div>
                </div>
                <div class="case_row" v-if="0">
                    <div class="card_title fold_title">
                        <i :class="isFold2 ? 'el-icon-arrow-up fold_icon' : 'el-icon-arrow-down fold_icon'" @click="isFold2 = !isFold2"></i>
                        <span>3分钟本地视频</span>
                    </div>
                    <div class="card_content text_columns clearfix" v-if="isFold2"></div>
                </div>
                <div class="case_row">
                    <div class="card_title fold_title">
                        <i :class="isFold3 ? 'el-icon-arrow-up fold_icon' : 'el-icon-arrow-down fold_icon'" @click="isFold3 = !isFold3"></i>
                        <span>出险前半小时告警</span>
                    </div>
                    <div class="card_content text_columns clearfix" v-if="isFold3">
                        <el-table ref="multipleTable" :data="resultData.alarmVos" max-height="500px">
                            <!-- <el-table-column prop="companyName" label="所属公司" width="120">
                            <template slot-scope="scope">
                                <overflow-txt :txt="scope.row.companyName"></overflow-txt>
                            </template>
                        </el-table-column> -->
                            <!-- <el-table-column prop="fleetName" label="所属车队" width="120"></el-table-column> -->
                            <el-table-column prop="plateNo" label="车牌号" width="100"></el-table-column>
                            <el-table-column prop="deviceNumber" label="设备号" width="120"></el-table-column>
                            <el-table-column prop="attachmentNum" label="附件">
                                <template slot-scope="scope">
                                    <!-- slot-scope="scope" -->
                                    <!-- <span class="clickTxt" v-if="scope.row.attachmentNum > 0" @click="showAttachment(scope.row)">{{ scope.row.attachmentNum }}</span>
                                <span v-else>{{ scope.row.attachmentNum }}</span> -->
                                    <span v-show="0">{{ scope.row.attachmentNum }}</span>
                                    <span class="clickTxt" @click="showAttachment(scope.row)">附件</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="alarmTypeText" label="告警类型" width="150"></el-table-column>
                            <el-table-column prop="startTime" label="开始时间" width="156"></el-table-column>
                            <!-- <el-table-column prop="endTime" label="结束时间" width="156"></el-table-column> -->
                            <el-table-column prop="alarmLevel" label="告警级别"></el-table-column>
                            <el-table-column prop="alarmSpeed" label="告警速度" width="100"></el-table-column>
                            <el-table-column prop="endLongitude" label="开始经纬度" width="100">
                                <template slot-scope="scope">
                                    <overflow-txt :txt="scope.row.startLongitude + '，' + scope.row.startLatitude" v-if="scope.row.startLongitude"></overflow-txt>
                                </template>
                            </el-table-column>
                            <el-table-column prop="startLocation" label="地址" width="100">
                                <template slot-scope="scope">
                                    <overflow-txt :txt="scope.row.startLocation"></overflow-txt>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="alarmStatusName" label="警情处理状态" width="120"></el-table-column> -->
                        </el-table>
                    </div>
                </div>
            </div>
            <el-empty class="result_box case_card" description="暂无数据" v-loading="searchFlag" v-else></el-empty>
        </div>

        <div class="tab-pane" v-show="activeName == 'second'">
            <div class="result_box case_card" v-loading="videoLoading" element-loading-text="视频下载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form size="small" :inline="true">
                        <el-form-item label="视频的时间">
                            <el-date-picker v-model="exportTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="mini" @click="exportVideo">查 询</el-button>
                            <el-button type="primary" size="mini" @click="refresh">刷 新</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
                <el-table :data="exportList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" width="150px" fixed />
                    <el-table-column label="提取时间" align="center" key="value" prop="value" />
                    <el-table-column label="提取结果" align="center" key="result" prop="result" />
                    <el-table-column label="文件列表" align="center">
                        <template slot-scope="scope">
                            <template v-for="(address, index) in scope.row.ftpAddressList">
                                <span :key="index" v-if="index > 0"> | </span><el-link :key="index" @click="downloadFile(address, scope.row, index)" type="primary">视频{{ address.indexOf('CH1') != -1 ? '(外)' : '(内)' }}</el-link>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 附件弹窗 -->
        <el-dialog class="attachment_dialog" :visible.sync="dialogVisible" width="1000px" :modal-append-to-body="false">
            <div v-loading="previewLoading">
                <div class="dialog_box" v-if="imgList.length > 0">
                    <div class="dialog_title">
                        <span>{{ attachmentRow.deviceNumber ? attachmentRow.deviceNumber : '--' }}</span>
                        <span class="warning_type">{{ attachmentRow.alarmTypeText ? attachmentRow.alarmTypeText : '--' }}</span>
                        <span>{{ attachmentRow.startTime ? attachmentRow.startTime : '--' }}</span>
                    </div>
                    <div class="attachment_box clearfix">
                        <div class="box_left fl" v-if="dialogVisible">
                            <img-deal ref="imgDeal" :url="currentItem.ossUrl" v-if="currentItem.isImg"></img-deal>
                            <video :src="currentItem.ossUrl" controls autoplay muted loop v-else></video>
                        </div>
                        <ul class="box_right fr">
                            <li :class="{ subsetActive: activeImgIndex == imgIndex, Img_li: 1 }" v-for="(imgItem, imgIndex) in imgList" :key="imgIndex" @click="previewItem(imgItem, imgIndex)">
                                <img :src="imgItem.ossUrl" v-if="imgItem.isImg" />
                                <video :src="currentItem.ossUrl" :poster="imgList[0].ossUrl" muted v-else></video>
                            </li>
                        </ul>
                    </div>
                </div>
                <el-empty description="暂无数据" v-else></el-empty>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            activeName: 'first',
            deviceNumber: '',
            videoLoading: false,
            searchFlag: false,
            isFold0: true,
            isFold1: true,
            isFold2: true,
            isFold3: true,
            isFold4: true,
            activeNames: ['1'],
            queryFrom: {
                accidentTime: null,
                plateNo: null
            },
            resultData: null,
            imgList: [],
            dialogVisible: false,
            previewLoading: false,
            attachmentRow: {},
            activeImgIndex: 0,
            currentItem: {},
            exportTime: '',
            exportList: []
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    methods: {
        tabClick() {},
        downloadFile(ftpAddress, deviceItem, index) {
            let deviceNumber = deviceItem.deviceNumber;
            let videoTime = deviceItem.value;
            const path = this.baseURL + '/operate/api/accidentReport/downloadVideo?ftpAddress=' + ftpAddress;
            this.videoLoading = true;

            this.instance({
                method: 'get',
                url: path,
                params: {},
                responseType: 'blob',
                headers: {}
            }).then((res) => {
                this.videoLoading = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', `${deviceNumber}_${videoTime}_${index + 1}.mp4`); //指定下载后的文件名，防跳转 video.mp4
                document.body.appendChild(link);
                link.click();
            });
        },
        exportVideo() {
            if (!this.resultData || !this.resultData.deviceInfo) {
                this.$message.error('请先查找设备信息');
            }
            const deviceNumber = this.resultData.deviceInfo.deviceNumber;
            let data = {
                deviceNumber: deviceNumber,
                time: this.exportTime
            };
            this.instance.post('/operate/api/dualCamera/startDualCameraVideoExportTask', data).then((res) => {
                this.$message.info(res.data.data);
                this.fetchExportList(this.resultData.deviceInfo.deviceNumber);
            });
        },
        fetchExportList(deviceNumber) {
            this.instance.get('/operate/api/accidentReport/fetchVideoExportRecord?deviceNumber=' + deviceNumber).then((res) => {
                console.log(res);
                this.exportList = res.data.data;
            });
        },
        refresh() {
            if (!this.resultData || !this.resultData.deviceInfo) {
                this.$message.error('请先查找设备信息');
            }
            this.fetchExportList(this.resultData.deviceInfo.deviceNumber);
        },
        searchData() {
            this.searchFlag = true;
            this.instance.post('/operate/api/accidentReport/findData', this.queryFrom).then((res) => {
                this.searchFlag = false;
                let data = res.data;
                if (data.code == 200) {
                    this.resultData = data.data;
                    this.fetchExportList(this.resultData.deviceInfo.deviceNumber);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        showAttachment(attachmentRow) {
            this.dialogVisible = true;
            this.attachmentRow = attachmentRow;
            this.getAttachments(attachmentRow);
        },
        getAttachments(rowItem) {
            this.previewLoading = true;
            this.imgList = [];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];

            this.instance.post('/operate/api/accidentReport/findAlarmAttachment/' + rowItem.alarmUploadId, {}).then((res) => {
                this.previewLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.imgList = data.data;
                    this.imgList.forEach((item) => {
                        item.fileType = item.fileName.split('.').pop();
                        item.isImg = fileTypeList.indexOf(item.fileType) != -1 ? true : false;
                    });
                    this.previewItem(this.imgList[0], 0);
                }
            });
        },
        previewItem(imgItem, imgIndex) {
            this.activeImgIndex = imgIndex;
            this.currentItem = imgItem;
        }
    }
};
</script>
<style lang="less">
@import '../../assets/css/caseCard.css';
</style>
<style lang="less" scoped>
.innerPage {
    /deep/ .el-tabs {
        .el-tabs__header {
            margin-bottom: 0 !important;
        }
    }
    /deep/ .el-tabs__nav-wrap::after {
        display: none !important;
    }
}
.result_box {
    // margin-top: 20px;
    padding: 20px;
    background: #fff;
    min-height: calc(100vh - 230px);
    .speed_box {
        max-height: 415px;
        overflow: auto;
    }
    .contentRow {
        // margin-bottom: 10px;
        .contentTxt {
            font-size: 14px;
        }
    }
    .addressStyle {
        margin-bottom: 15px;
    }
}

.attachment_dialog {
    /deep/ .el-dialog__body {
        padding-top: 0;
    }
    .dialog_title {
        span {
            color: #333;
            font-size: 16px;
        }
        .warning_type {
            margin: 0 30px;
        }
    }
    .attachment_box {
        height: 550px;
        .box_left {
            width: 780px;
            height: 550px;
            padding-right: 40px;
            border-right: 1px solid #8b8b8b;
            video {
                width: 100%;
                height: 100%;
                // border: 1px solid #ccc;
            }
        }
        .box_right {
            width: 170px;
            height: 550px;
            overflow-y: auto;
            // margin-right: 20px;
            text-align: center;
            padding-left: 25px;
            li {
                width: 120px;
                height: 120px;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            li:last-child {
                margin-bottom: 0;
            }
            .subsetActive {
                border: 2px solid red !important;
            }
        }
    }
}
</style>