<template>
    <div class="iframe-box">
        <div class="zhegai"></div>
        <iframe id="iframe1" :style="'height:' + resizeHeight" v-if="iframeSrc.length >= 0" :src="iframeSrc" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    data() {
        return {
            testNum: 0,
            iframeSrc: '',
            resizeHeight: '653'
        }
    },
    mounted() {
        this.getDashboard()
        this.resizeHeight = window.innerHeight - 10 + 'px'
        window.onresize = () => {
            this.resizeHeight = window.innerHeight - 10 + 'px'
        }
    },
    methods: {
        testIframe() {
            this.testNum++
            if (this.testNum > 5) {
                this.iframeSrc = 'http://jiaanantest.platform.sigreal.cn/staging/workManage?t=123'
                setTimeout(() => {
                    const iframe = document.getElementById('iframe1')
                    const iframeWindow = iframe.contentWindow
                    const iframeDocument = iframeWindow.document
                    const element = iframeDocument.getElementById('pageTitle')
                    element.innerHTML = 'Hello World!'
                    element.style.color = 'red'
                }, 5000)
            }
        },
        getDashboard() {
            this.instance.get('/operate/api/case/dashboard/dualCameraDashboard').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.iframeSrc = data.data
                }
            })
        }
    }
}
</script>
<style scope>
.iframe-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: #fff;
}

#iframe1 {
    width: 100%;
    height: 780px;
}
.height_653 {
    height: 653px;
}
</style>