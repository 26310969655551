<template>
    <div class="app-container">
        <el-tabs class="dualCameraSetting" v-model="activeName">
            <el-tab-pane label="双摄设备管理" name="first">
                <div v-loading="deviceLoading">
                    <el-row :gutter="40" style="margin-left: 10px">
                        <el-form :model="deviceQuery" ref="deviceQueryForm" size="small" :inline="true">
                            <el-form-item label="设备号/车牌号/VIN">
                                <el-input v-model="deviceQuery.condition.search" placeholder="请输入设备号/车牌号/VIN" clearable />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleDeviceQuery">搜索 </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetDeviceQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-row>
                    <el-row>
                        <div style="text-align: right; height: 40px">
                            <el-button @click="batchSendTextCMD" type="primary" size="mini">发送命令</el-button>
                            <el-button @click="batchSendLSCMD" type="primary" size="mini">查看目录</el-button>
                            <el-button @click="openOverSpeedBatch" type="primary" size="mini">设置超速</el-button>
                            <el-button @click="opendBatchSendConfig" type="primary" size="mini">设置AI参数</el-button>
                            <el-button @click="batchDeviceAttribute" type="primary" size="mini">查看设备属性</el-button>
                        </div>
                        <el-table :data="deviceList" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55"> </el-table-column>
                            <el-table-column label="设备号" align="center" prop="deviceNumber" width="120"> </el-table-column>
                            <el-table-column label="车牌号" align="center" prop="plateNo"> </el-table-column>
                            <el-table-column label="VIN" align="center" prop="vin"> </el-table-column>
                            <el-table-column label="最后点位" align="center" width="100">
                                <template slot-scope="scope">
                                    <el-link @click="showLastPoint(scope.row)" type="primary">查看</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="最后行程" align="center" width="100">
                                <template slot-scope="scope">
                                    <el-link @click="showLastTrip(scope.row)" type="primary">查看</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="最后告警" align="center" width="100">
                                <template slot-scope="scope">
                                    <el-link @click="showLastAlarm(scope.row)" type="primary">查看</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-link @click="sendTextCMD(scope.row)" type="primary">发送命令</el-link> | <el-link @click="sendLsDir(scope.row)" type="primary">查看目录</el-link> | <el-link @click="openOverSpeed(scope.row)" type="primary">打开超速</el-link> |
                                    <el-link @click="sendDeviceAttribute(scope.row)" type="primary">设备属性</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                        <pagination v-show="deviceTotal > 0" :total="deviceTotal" :page.sync="deviceQuery.pageNo" :limit.sync="deviceQuery.pageSize" @pagination="fetchDeviceList" />
                    </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数下发日志" name="second">
                <div class="app-container">
                    <div>
                        <el-row :gutter="40" style="margin-left: 10px">
                            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                                <el-form-item label="设备号：">
                                    <el-input v-model="queryParams.condition.deviceNumber" placeholder="请输入设备号" clearable />
                                </el-form-item>
                                <el-form-item label="参数状态：">
                                    <el-select v-model="queryParams.condition.configStatus" placeholder="请选择参数状态" clearable>
                                        <el-option label="已废弃" value="0" key="已废弃"></el-option>
                                        <el-option label="已启用" value="1" key="已启用"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="发送状态：">
                                    <el-select v-model="queryParams.condition.sendStatus" placeholder="请选择发送状态" clearable>
                                        <el-option label="未下发设备" value="0" key="未下发设备"></el-option>
                                        <el-option label="已下发设备" value="1" key="已下发设备"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-row>
                    </div>
                    <div>
                        <div style="text-align: right; height: 40px"></div>
                        <el-table :data="recordList">
                            <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" width="150px" fixed />
                            <el-table-column label="参数名称" align="center" key="key" prop="key">
                                <template slot-scope="scope">
                                    <template v-if="scope.row.cmdType == 2">
                                        <el-link @click="showLSResult(scope.row)" type="primary">{{ scope.row.key }}</el-link>
                                    </template>
                                    <template v-else-if="scope.row.cmdType == 4">
                                        <el-link @click="showDeviceAttributeResult(scope.row)" type="primary">{{ scope.row.key }}</el-link>
                                    </template>
                                    <template v-else>
                                        {{ scope.row.key }}
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column label="参数值" align="center" key="value" prop="value" />
                            <el-table-column label="提交时间" align="center" key="updateTime" prop="updateTime" />
                            <el-table-column label="参数状态" align="center" key="configStatus" prop="configStatus">
                                <template slot-scope="scope">
                                    {{ scope.row.configStatus == 0 ? '已废弃' : '已启用' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="发送状态" align="center" key="sendStatus" prop="sendStatus">
                                <template slot-scope="scope">
                                    {{ scope.row.sendStatus == 0 ? '未下发设备' : '已下发设备' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="发送成功时间" align="center" key="sendTime" prop="sendTime" />
                        </el-table>
                        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchRecordList" />
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="监测问题设备" name="third">
                <el-row>
                    <div style="text-align: right; height: 40px">
                        <el-button @click="directoryMonitoring" type="primary" size="mini">设备目录监测</el-button>
                        <el-button @click="faultyDeviceVisible = true" type="primary" size="mini">监测问题设备</el-button>
                        <el-button :disabled="errorFrom.pageTotal == 0" @click="downloadErrorPageFile" type="primary" size="mini">下 载</el-button>
                    </div>
                    <el-table :data="errorPageData">
                        <el-table-column label="设备号" align="center" prop="deviceNumber" width="120"> </el-table-column>
                        <el-table-column label="车牌号" align="center" prop="plateNo"> </el-table-column>
                        <el-table-column label="VIN" align="center" prop="vin"> </el-table-column>
                    </el-table>
                    <pagination v-show="errorFrom.pageTotal > 0" :total="errorFrom.pageTotal" :page.sync="errorFrom.pageNo" :limit.sync="errorFrom.pageSize" @pagination="directoryMonitoring" />
                </el-row>
            </el-tab-pane>
        </el-tabs>

        <!-- 最后点位 弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="最后点位" :visible.sync="lastPointVisible" width="500px" @close="lastPointVisible = false">
            <p>ACC：{{ lastPointInfo.acc }}</p>
            <p style="margin: 8px 0">经纬度：{{ lastPointInfo.lat }} <span v-if="lastPointInfo.lon">,</span> {{ lastPointInfo.lon }}</p>
            <p>点位时间：{{ lastPointInfo.timeText }}</p>
        </el-dialog>
        <!-- 最后行程 弹窗 -->
        <el-dialog class="lastTripDialog" center :modal-append-to-body="false" title="最后行程" :visible.sync="lastTripVisible" width="500px" @close="lastTripVisible = false">
            <p>设备号：{{ lastTripInfo.deviceNumber ? lastTripInfo.deviceNumber : '--' }}</p>
            <p>驾驶时长：{{ lastTripInfo.drivingDuration ? lastTripInfo.drivingDuration : '--' }} 小时</p>
            <p>开始时间：{{ lastTripInfo.startTime ? lastTripInfo.startTime : '--' }}</p>
            <p>结束时间：{{ lastTripInfo.endTime ? lastTripInfo.endTime : '--' }}</p>
            <p>开始地址：{{ lastTripInfo.startAddress ? lastTripInfo.startAddress : '--' }}</p>
            <p>结束地址：{{ lastTripInfo.endAddress ? lastTripInfo.endAddress : '--' }}</p>
        </el-dialog>
        <!-- 最后告警 弹窗 -->
        <el-dialog class="lastAlarmDialog" center :modal-append-to-body="false" title="最后告警" :visible.sync="lastAlarmVisible" width="500px" @close="lastAlarmVisible = false">
            <p>告警类型：{{ lastAlarmInfo.alarmTypeText ? lastAlarmInfo.alarmTypeText : '--' }}</p>
            <p>设备号：{{ lastAlarmInfo.deviceNumber ? lastAlarmInfo.deviceNumber : '--' }}</p>
            <p>车牌号：{{ lastAlarmInfo.plateNo ? lastAlarmInfo.plateNo : '--' }}</p>
            <p>告警地点：{{ lastAlarmInfo.startLocation ? lastAlarmInfo.startLocation : '--' }}</p>
            <p>告警时间：{{ lastAlarmInfo.startTime ? lastAlarmInfo.startTime : '--' }}</p>
        </el-dialog>
        <!-- 监测问题设备 弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="监测问题设备" :visible.sync="faultyDeviceVisible" width="600px">
            <el-form :model="taskFrom" ref="taskFromRef" size="small" label-width="100px">
                <el-form-item label="设备号：" prop="sendDeviceNumber">
                    <el-input v-model="taskFrom.devices" type="textarea" :rows="8" placeholder="请输入设备号，多个设备用中文逗号分隔" clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="faultyDeviceVisible = false">取 消</el-button>
                <el-button type="primary" @click="checkTask">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 发送文本命令 弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="发送文本命令" width="600px" :visible.sync="sendTextCMDVisible" @close="sendTextCMDVisible = false">
            <el-form :model="sendTextCMDData" ref="sendTextCMDDataForm" size="small" label-width="100px">
                <el-form-item label="文本命令：" prop="sendCMD">
                    <el-select class="inheritWidth" v-model="sendTextCMDData.sendCMD" placeholder="请选择" filterable clearable>
                        <template v-for="(item, index) in commandOptions">
                            <el-option :label="item.label" :value="item.value" :key="index"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="sendCMD">
                    <el-input v-model="sendTextCMDData.sendCMD"></el-input>
                </el-form-item>

                <el-form-item label="设备号：" prop="sendDeviceNumber">
                    <el-input type="textarea" :rows="8" placeholder="请输入设备号，多个设备用逗号分隔" v-model="sendTextCMDData.sendDeviceNumber" clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="sendTextCMDVisible = false">取 消</el-button>
                <el-button type="primary" @click="doSendTextCMD">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看设备目录 弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="查看设备目录" :visible.sync="sendLSCMDVisible" width="600px" @close="sendLSCMDVisible = false">
            <el-form :model="sendLSCMDData" ref="sendLSCMDDataForm" size="small" label-width="100px">
                <el-form-item label="目录地址：" prop="sendCMD">
                    <el-select class="inheritWidth" v-model="sendLSCMDData.sendCMD" placeholder="请选择" filterable clearable>
                        <template v-for="(item, index) in directoryOptions">
                            <el-option :label="item.label" :value="item.value" :key="index"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="sendCMD">
                    <el-input v-model="sendLSCMDData.sendCMD"></el-input>
                </el-form-item>

                <el-form-item label="设备号：" prop="sendDeviceNumber">
                    <el-input type="textarea" :rows="8" placeholder="请输入设备号，多个设备用逗号分隔" v-model="sendLSCMDData.sendDeviceNumber" clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="sendLSCMDVisible = false">取 消</el-button>
                <el-button type="primary" @click="doSendLSCMD">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 双摄设备配置 弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="双摄设备配置" :visible.sync="paramsSettingVisible" width="700px" @close="closeSendConfig()">
            <el-form :model="settingParams" ref="settingForm" size="small" label-width="200px" :rules="rules">
                <el-form-item label="front_start_alarm_interval：" prop="front_start_alarm_interval">
                    <el-input v-model="settingParams.settings.front_start_alarm_interval" clearable />
                </el-form-item>
                <el-form-item label="front_speed_enable：" prop="front_speed_enable">
                    <el-input v-model="settingParams.settings.front_speed_enable" clearable />
                </el-form-item>
                <el-form-item label="ped_speed_enable：" prop="ped_speed_enable">
                    <el-input v-model="settingParams.settings.ped_speed_enable" clearable />
                </el-form-item>
                <el-form-item label="front_start_report_interval：" prop="front_start_report_interval">
                    <el-input v-model="settingParams.settings.front_start_report_interval" clearable />
                </el-form-item>
                <el-form-item label="设备号：" prop="deviceArray">
                    <el-input type="textarea" :rows="8" placeholder="请输入设备号，多个设备用逗号分隔" v-model="settingParams.deviceArray" clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeSendConfig()">取 消</el-button>
                <el-button type="primary" @click="doSendSetting(true)">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 目录查看结果 弹窗 -->
        <el-dialog class="cmd_dialog" center :modal-append-to-body="false" title="目录查看结果" :visible.sync="lsCmdResultVisible" width="800px" @close="lsCmdResultVisible = false">
            <div>
                <p class="cmd_title"><span class="fontSeat">占</span>运行结果：</p>
                <div class="cmd_box">
                    <template v-if="cmdList && cmdList.length > 0">
                        <p v-for="(line, index) in cmdList" :key="index">{{ line }}</p>
                    </template>
                    <p class="text_center" v-if="cmdList && cmdList.length == 0 && loadDataFlag">暂无数据</p>
                </div>
            </div>
        </el-dialog>
        <el-dialog class="lastAlarmDialog" center :modal-append-to-body="false" title="设备属性详情" :visible.sync="deviceAttributeVisible" width="600px" @close="deviceAttributeVisible = false">
            <p>iccid：{{ deviceAttribute.iccid ? deviceAttribute.iccid : '--' }}</p>
            <p>version：{{ String(deviceAttribute.version) != 'null' ? deviceAttribute.version : '--' }}</p>
            <p>verified：{{ String(deviceAttribute.verified) != 'null' ? deviceAttribute.verified : '--' }}</p>
            <p>hardwareVersion：{{ deviceAttribute.hardwareVersion ? deviceAttribute.hardwareVersion : '--' }}</p>
            <p>firmwareVersion：{{ deviceAttribute.firmwareVersion ? deviceAttribute.firmwareVersion : '--' }}</p>
        </el-dialog>
        <!-- 打开超速弹窗 -->
        <el-dialog center :modal-append-to-body="false" title="设置超速" :visible.sync="overSpeedSettingVisible" width="600px" @close="overSpeedSettingVisible = false">
            <el-form :model="overSpeedSettingData" ref="overSpeedSettingDataForm" size="small" label-width="100px">
                <el-form-item label="超速最大速度" prop="maxSpeed">
                    <el-input-number v-model="overSpeedSettingData.maxSpeed" />
                </el-form-item>
                <el-form-item label="超速预警差值" prop="speedDiff">
                    <el-input-number v-model="overSpeedSettingData.speedDiff" />
                </el-form-item>
                <el-form-item label="超速持续时间" prop="overspeedDuration">
                    <el-input-number v-model="overSpeedSettingData.overspeedDuration" />
                </el-form-item>
                <el-form-item label="设备号：" prop="deviceArray">
                    <el-input type="textarea" :rows="8" placeholder="请输入设备号，多个设备用逗号分隔" v-model="overSpeedSettingData.deviceArray" clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="overSpeedSettingVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendOverSpeedSetting">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import OSS from 'ali-oss'
export default {
    name: 'bindManager',
    data() {
        return {
            tmpOSSClient: null,
            resetOssNum: 0,
            fileFLag: false,
            errorPageData: [],
            errorFrom: {
                condition: {},
                pageTotal: 0,
                pageNo: 1,
                pageSize: 10
            },
            taskFrom: {
                devices: ''
            },
            faultyDeviceVisible: false,
            loadDataFlag: false,
            title: '添加',
            loading: false,
            activeName: 'first',
            deviceLoading: false,
            lastPointVisible: false,
            lastPointInfo: {},
            lastTripVisible: false,
            lastTripInfo: {},
            lastAlarmVisible: false,
            lastAlarmInfo: {},
            sendTextCMDVisible: false,
            sendLSCMDVisible: false,
            selectDevice: [],
            deviceList: [],
            deviceQuery: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },
            sendTextCMDData: {
                sendDeviceNumber: '',
                sendCMD: ''
            },
            sendLSCMDData: {
                sendDeviceNumber: '',
                sendCMD: ''
            },
            overSpeedSettingVisible: false,
            overSpeedSettingData: {
                maxSpeed: 120,
                speedDiff: 20,
                overspeedDuration: 5
            },
            deviceAttributeVisible: false,
            deviceAttribute: '暂无数据',
            recordFormVisible: false,
            uploadRecordsVisible: false,
            deviceConfigsVisible: false,
            paramsSettingVisible: false,
            tenants: [
                { label: '环球车享', value: 10004 },
                { label: '太保商用车', value: 10049 }
            ],
            rootTenantId: null,
            subTenantId: null,
            companyList: [],
            fleetList: [],
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },

            recordList: [],
            total: 0,
            deviceTotal: 0,
            recordForm: {},
            settingParams: {
                settings: {
                    front_start_alarm_interval: 3600,
                    front_speed_enable: 30,
                    ped_speed_enable: 20,
                    front_start_report_interval: 3600
                }
            },
            settingForm: undefined,
            fileList: [],
            rules: {
                deviceNumber: [
                    { required: true, message: '设备号不能为空', trigger: 'blur' },
                    { min: 10, max: 15, message: '设备号长度在 10 到 15 位', trigger: 'blur' }
                ]
            },
            baseURL: '',
            dualCamera: {},
            uploadConfigFileLoading: false,
            dualCameraConfigRecordList: [],
            dualCameraConfigFileList: [],
            lsCmdResultVisible: false,
            cmdList: [],
            commandOptions: [
                {
                    label: '状态查询',
                    value: 'STATUSVIDEO'
                },
                {
                    label: '版本号查询',
                    value: 'VERSION'
                },
                {
                    label: '设备重启',
                    value: 'REBOOT'
                },
                {
                    label: 'TF卡格式化',
                    value: 'FORMAT,1'
                },
                {
                    label: '半恢复出厂设置',
                    value: 'RESTORE'
                },
                {
                    label: '全恢复出厂设置',
                    value: 'RESTOREALL'
                },
                {
                    label: '时区查询',
                    value: 'TIMEZONE,A,B,C'
                },
                {
                    label: '语言查询',
                    value: 'VOICESW,0'
                },
                {
                    label: '输出音量查询',
                    value: 'VOLUME,1'
                },
                {
                    label: 'APN 查询',
                    value: 'APN,A,B'
                },
                {
                    label: '中心1查询',
                    value: 'CENTER,A,B,C'
                },
                {
                    label: '中心2查询',
                    value: 'CENTER1,A,B,C'
                },
                {
                    label: '部标参数查询',
                    value: 'JTBASE,PHONENUM,025069737766,LICENSENUM,京A8888,TERMINALID,1234'
                },
                {
                    label: '休眠唤醒后工作时长',
                    value: 'WORKTIME,A'
                },
                {
                    label: 'ACC延时关机',
                    value: 'SHUTDOWNTIME,A'
                },
                {
                    label: '设置主码流',
                    value: 'VIDEORSL,A,B,C,D,E'
                },
                {
                    label: '设置子码流（同主码流）',
                    value: 'VIDEORSL_SUB'
                },
                {
                    label: '设置镜像翻转',
                    value: 'RATATION,A,B'
                },
                {
                    label: 'ACC延时关机',
                    value: 'SHUTDOWNTIME,A'
                },
                {
                    label: 'AI开关',
                    value: 'DMSSW,A,B'
                },
                {
                    label: 'AI模拟速度',
                    value: 'DMSVSP,60'
                },
                {
                    label: '设置AI报警间隔',
                    value: 'EVENTALART,A,B,C'
                },
                {
                    label: '设置AI报警开关',
                    value: 'EVENTSET,A,B'
                },
                {
                    label: '设置ACC模式',
                    value: 'ACCMODE,A'
                },
                {
                    label: '设置设备型号',
                    value: 'DEVNAME,A'
                },
                {
                    label: 'FTP升级',
                    value: 'FTPUPDATE,A,B,C,D,E'
                },
                {
                    label: 'FTP日志上传（同上）',
                    value: 'FTPLOG,A,B,C,D,E'
                },
                {
                    label: '低压设置',
                    value: 'LOWVOLTAGE,11.5'
                },
                {
                    label: '配置项功能（用@分隔多条指令，这些指令会保存成配置文件）',
                    value: 'CONFIGS,@VERSION@STATUSVIDEO'
                }
            ],
            directoryOptions: [
                {
                    label: '/mnt/card',
                    value: '/mnt/card'
                },
                {
                    label: '/mnt/card/Event',
                    value: '/mnt/card/Event'
                },
                {
                    label: '/mnt/card/General',
                    value: '/mnt/card/General'
                },
                {
                    label: '/mnt/card/Log',
                    value: '/mnt/card/Log'
                },
                {
                    label: '/mnt/card/Program',
                    value: '/mnt/card/Program'
                },
                {
                    label: '/mnt/card/General/CH1',
                    value: '/mnt/card/General/CH1'
                },
                {
                    label: '/mnt/card/General/CH2',
                    value: '/mnt/card/General/CH2'
                }
            ]
        }
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL
    },
    created() {
        //this.fetchCompanyList();
        this.initOSSClient()
        this.fetchRecordList()
        this.fetchDeviceList()
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null
            this.resetOssNum++
            this.instance
                .get('/operate/api/aliyun/oss/access?bucket=ubi-operational-report')
                .then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: 'ubi-operational-report'
                        })
                    } else {
                        console.log('oss初始化失败，正在重试！')

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient()
                            }, 1000)
                        } else {
                            console.log('oss初始化超过20s，失败!')
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-')
                    console.log(err)
                })
        },

        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options)
                console.log('文件存在')
                this.fileFLag = true
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    console.log('文件不存在')
                    this.fileFLag = false
                } else {
                    console.log('error:')
                    console.log(error)
                }
            }
        },
        getCurrentDateInYYYYMMDD() {
            const currentDate = new Date()
            const year = currentDate.getFullYear()
            // 月份从 0 开始计数，所以要加 1，并且保证为两位数
            const month = String(currentDate.getMonth() + 1).padStart(2, '0')
            // 日期保证为两位数
            const day = String(currentDate.getDate()).padStart(2, '0')

            return `${year}${month}${day}`
        },
        downloadErrorPageFile() {
            // let fileStr = '/checkReport/双摄告警-前向碰撞报警检测-20250120.xlsx';
            let fileStr = `/checkReport/双摄告警-前向碰撞报警检测-${this.getCurrentDateInYYYYMMDD()}.xlsx`
            console.log(fileStr)

            this.downloadFile(fileStr)
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this[btnLoading] = true
                this.isExistObject(fileKey)
                if (this.fileTime > 200) {
                    this[btnLoading] = false
                    this.$message.error('文件下载失败，请稍后重试！')
                    return
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++
                        this.downloadFile(fileKey, btnLoading)
                    }, 3000)
                } else {
                    this.fileFLag = false
                    this.fileTime = 0
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey)
                    this[btnLoading] = false
                    location.href = fileUrl
                }
            }
        },
        directoryMonitoring() {
            this.instance.post('/operate/api/dualCamera/errorDevicePage', this.errorFrom).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.errorPageData = data.data.list
                    this.errorFrom.pageTotal = data.data.total
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        checkTask() {
            let devicesArr = this.taskFrom.devices.split('，')

            this.instance.post('/operate/api/dualCamera/startDucalCameraCheckTask', { deviceNumberList: devicesArr }).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success('请求成功！')
                    this.faultyDeviceVisible = false
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        goSendResult() {
            this.activeName = 'second'
            this.resetWithLoadRecord()
        },
        showDeviceAttributeResult(record) {
            this.deviceAttributeVisible = true
            if (record.sendStatus == 0) {
                this.deviceAttribute = '设备不在线'
            } else {
                // let formattedJson = JSON.stringify(JSON.parse(record.result), null, 2);
                let formattedJson = JSON.parse(record.result)

                this.deviceAttribute = formattedJson
            }
        },
        batchDeviceAttribute() {
            let data = {
                deviceArray: this.getDeviceArray()
            }
            this.instance.post('/operate/api/dualCamera/sendDeviceAttribute', data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data)
                    this.goSendResult()
                } else {
                    this.$message.error('提交失败')
                }
            })
        },
        sendDeviceAttribute(device) {
            let data = {
                deviceArray: device.deviceNumber
            }
            this.instance.post('/operate/api/dualCamera/sendDeviceAttribute', data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data)
                    this.goSendResult()
                } else {
                    this.$message.error('提交失败')
                }
            })
        },
        openOverSpeed(device) {
            this.overSpeedSettingVisible = true
            this.overSpeedSettingData = {
                deviceArray: device.deviceNumber,
                maxSpeed: 120,
                speedDiff: 20,
                overspeedDuration: 5
            }
        },
        openOverSpeedBatch() {
            this.overSpeedSettingVisible = true
            this.overSpeedSettingData = {
                deviceArray: this.getDeviceArray(),
                maxSpeed: 140,
                speedDiff: 20,
                overspeedDuration: 5
            }
        },
        sendOverSpeedSetting() {
            let data = JSON.parse(JSON.stringify(this.overSpeedSettingData))

            this.instance.post('/operate/api/dualCamera/sendOverSpeed', data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data)
                    this.goSendResult()
                } else {
                    this.$message.error('提交失败')
                }
            })
        },
        getDeviceArray() {
            if (this.selectDevice && this.selectDevice.length > 0) {
                let deviceArray = this.selectDevice[0].deviceNumber
                for (let i = 1; i < this.selectDevice.length; i++) {
                    deviceArray = deviceArray + ',' + this.selectDevice[i].deviceNumber
                }
                return deviceArray
            } else {
                return ''
            }
        },
        handleSelectionChange(val) {
            this.selectDevice = val
        },
        numberedLines(cmdStr) {
            // 将字符串按换行符拆分成行数组
            const lines = cmdStr.split('\n')
            // 过滤掉空行
            const nonEmptyLines = lines.filter((line) => line.trim() !== '')
            // 为每一行添加行号
            this.cmdList = nonEmptyLines.map((line, index) => `${index + 1}. ${line}`)
        },
        showLSResult(cmdRecord) {
            this.lsCmdResultVisible = true
            this.loadDataFlag = false
            this.instance.get('/operate/api/dualCamera/getDeviceLSCMDResult?requestId=' + cmdRecord.requestId).then((res) => {
                let cmdStr = res.data.data
                this.numberedLines(cmdStr)
                this.loadDataFlag = true
            })
        },
        showLastPoint(device) {
            this.lastPointInfo = {}
            this.lastPointVisible = true
            this.instance.get('/operate/api/dualCamera/lastPoint?deviceNumber=' + device.deviceNumber).then((res) => {
                this.lastPointInfo = res.data.data
            })
        },
        showLastTrip(device) {
            this.lastTripInfo = {}
            this.lastTripVisible = true
            this.instance.get('/operate/api/dualCamera/lastTrip?deviceNumber=' + device.deviceNumber).then((res) => {
                this.lastTripInfo = res.data.data
            })
        },
        showLastAlarm(device) {
            this.lastAlarmInfo = {}
            this.lastAlarmVisible = true
            this.instance.get('/operate/api/dualCamera/lastAlarm?deviceNumber=' + device.deviceNumber).then((res) => {
                this.lastAlarmInfo = res.data.data
            })
        },
        sendTextCMD(device) {
            this.sendTextCMDVisible = true
            this.sendTextCMDData = {
                sendDeviceNumber: device.deviceNumber,
                sendCMD: ''
            }
        },
        batchSendTextCMD() {
            this.sendTextCMDVisible = true
            let deviceArray = this.getDeviceArray()

            this.sendTextCMDData = {
                sendDeviceNumber: deviceArray,
                sendCMD: ''
            }
        },
        batchSendLSCMD() {
            this.sendLSCMDVisible = true
            let deviceArray = this.getDeviceArray()

            this.sendLSCMDData = {
                sendDeviceNumber: deviceArray,
                sendCMD: ''
            }
        },
        doSendTextCMD() {
            let data = {
                deviceArray: this.sendTextCMDData.sendDeviceNumber,
                cmd: this.sendTextCMDData.sendCMD
            }
            this.instance.post('/operate/api/dualCamera/sendCMD', data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data)
                    this.goSendResult()
                } else {
                    this.$message.error('提交失败')
                }
            })
        },
        sendLsDir(device) {
            this.sendLSCMDVisible = true
            this.sendLSCMDData = {
                sendDeviceNumber: device.deviceNumber,
                sendCMD: ''
            }
        },
        doSendLSCMD() {
            let data = {
                deviceArray: this.sendLSCMDData.sendDeviceNumber,
                dir: this.sendLSCMDData.sendCMD
            }
            this.instance.post('/operate/api/dualCamera/sendViewDeviceDir', data).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data)
                    this.goSendResult()
                } else {
                    this.$message.error('提交失败')
                }
            })
        },
        fetchDeviceList() {
            this.deviceLoading = true

            let data = JSON.parse(JSON.stringify(this.deviceQuery))

            this.instance.post('/operate/api/dualCamera/devicePage', data).then((res) => {
                this.deviceLoading = false
                this.deviceList = res.data.data.list
                this.deviceTotal = parseInt(res.data.data.total)
            })
        },
        sendConfigFileBatch(configFile) {
            const version = configFile.version
            this.$prompt('请输入要下发的设备号，多个设备已逗号分隔', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    const data = {
                        deviceNumber: value,
                        value: version
                    }
                    this.instance
                        .post('/operate/api/dualCamera/sendDeviceConfigBatch', data)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$message.success('配置' + version + '已成功下发')
                                this.closeSendConfig()
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                        .catch((res) => {
                            this.$message.error('请求失败! 请稍后重试')
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    })
                })
        },
        closeSendConfig() {
            this.paramsSettingVisible = false
        },
        opendSendConfig(device) {
            this.settingParams.deviceArray = device.deviceNumber
            this.paramsSettingVisible = true
        },
        opendBatchSendConfig() {
            this.paramsSettingVisible = true
            this.settingParams.deviceArray = this.getDeviceArray()
        },
        doSendSetting() {
            if (!this.settingParams.deviceArray) {
                this.$message.error('请输入设备号')
                return
            }
            if (!this.settingParams.settings.front_start_alarm_interval) {
                this.$message.error('请输入front_start_alarm_interval')
                return
            }
            if (!this.settingParams.settings.front_speed_enable) {
                this.$message.error('请输入front_speed_enable')
                return
            }
            if (!this.settingParams.settings.ped_speed_enable) {
                this.$message.error('请输入ped_speed_enable')
                return
            }
            if (!this.settingParams.settings.front_start_report_interval) {
                this.$message.error('请输入front_start_report_interval')
                return
            }
            let data = JSON.parse(JSON.stringify(this.settingParams))
            this.loading = true
            this.instance.post('/operate/api/dualCameraSetting/sendSettingItems', data).then((res) => {
                this.loading = false
                this.$message.success('提交成功')
                this.resetWithLoadRecord()
                this.paramsSettingVisible = false
            })
        },
        fetchRecordList() {
            this.loading = true

            let data = JSON.parse(JSON.stringify(this.queryParams))

            this.instance.post('/operate/api/dualCameraSetting/records', data).then((res) => {
                this.loading = false
                this.recordList = res.data.data.list
                this.total = parseInt(res.data.data.total)
            })
        },
        handleDeviceQuery() {
            this.deviceQuery.pageNo = 1
            this.fetchDeviceList()
        },
        resetDeviceQuery() {
            this.deviceQuery = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            }
        },
        handleQuery() {
            this.queryParams.pageNo = 1
            this.fetchRecordList()
        },
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            }
        },
        reset() {
            this.resetQuery()
        },
        resetWithLoadRecord() {
            this.reset()
            this.fetchRecordList()
        }
    }
}
</script>
<style lang="less" scoped>
.dualCameraSetting {
    background: white;
    padding: 20px;
    margin: 10px;
}
.lastTripDialog,
.lastAlarmDialog {
    p {
        margin-bottom: 8px;
    }
}
.cmd_dialog {
    .cmd_title {
        background: black;
        color: white;
        padding: 6px 0;
        border-bottom: 2px solid #999999;
    }
    .cmd_box {
        background: black;
        color: white;
        padding: 10px 15px;
        max-height: 510px;
        overflow: auto;
        p {
            line-height: 26px;
        }
    }
}
</style>