<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="line-height: 30px">设备库存管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号">
                            <el-input v-model="queryParams.condition.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="设备类型" >
                            <el-select style="width: 100%" v-model="queryParams.condition.deviceType" placeholder="请选择设备类型">
                                <el-option label="OBD设备" value="0" key="0"></el-option>
                                <el-option label="双摄设备" value="1" key="1" ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="项目状态">
                            <el-select v-model="queryParams.condition.projectStatus" placeholder="请选择项目状态" clearable>
                                <el-option label="项目停止运维" value="项目停止运维" key="项目停止运维"></el-option>
                                <el-option label="设备停止运维" value="设备停止运维" key="设备停止运维"></el-option>
                                <el-option label="项目正常运维" value="项目正常运维" key="项目正常运维"></el-option>
                                <el-option label="退回厂家，已换4G设备" value="退回厂家，已换4G设备" key="退回厂家，已换4G设备"></el-option>
                                <el-option label="在库" value="在库" key="在库"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="续费状态">
                            <el-select v-model="queryParams.condition.renewStatus" placeholder="请选择变更状态" clearable>
                                <el-option label="已续费" value="1000" key="1000"></el-option>
                                <el-option label="即将续费" value="1001" key="1001"></el-option>
                                <el-option label="过期未续费" value="1002" key="1002"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户总公司">
                            <el-select v-model="queryParams.condition.rootTenantId" placeholder="请选择客户总公司" filterable clearable @change="changeTenant" clearable>
                                <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户分公司">
                            <el-select style="width: 100%" v-model="queryParams.condition.subTenantId" placeholder="请选择客户分公司" filterable clearable @change="changeCompany" clearable>
                                <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户车队">
                            <el-select style="width: 100%" v-model="queryParams.condition.fleetId"  filterable clearable placeholder="请选择客户车队" clearable>
                                <el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
            
                        <el-form-item label="入库日期">
                            <el-date-picker 
                                v-model="queryParams.condition.inStorageTimeRange" 
                                type="daterange" 
                                format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期"> 
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="出库日期">
                            <el-date-picker 
                                v-model="queryParams.condition.outStorageTimeRange" 
                                type="daterange" 
                                format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期"> 
                            </el-date-picker>
                        </el-form-item>

                      <el-form-item label="租户续费日期">
                        <el-date-picker
                            v-model="queryParams.condition.tenantExpirationTimeRange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="厂商续费日期">
                        <el-date-picker
                            v-model="queryParams.condition.supplierExpirationTimeRange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="数据统计日期">
                        <el-date-picker
                            v-model="queryParams.condition.dataTimeRange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                      </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <div style="text-align: right; height: 40px">
                    <el-link :href="baseURL + '/operate/api/device/stockTemplate'" type="primary">下载导入模板：台账模板.xlsx</el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button @click="uploadRecords" type="primary" icon="el-icon-upload2" size="mini">批量导入</el-button>
                    <el-button @click="addRecord" type="primary" icon="el-icon-plus" size="mini">新增记录</el-button>
                    <el-button @click="opendSendConfig" type="primary" icon="el-icon-plus" size="mini">下发双摄设备配置文件</el-button>

                    <el-button @click="submitDownloadTask" icon="el-icon-download" type="primary" size="mini">提交下载任务</el-button>
                    <el-button @click="downloadTable" icon="el-icon-download" type="primary" size="mini"></el-button>
                </div>
                <el-table :data="recordList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" width="100px" fixed />
                    <el-table-column label="设备类型" align="center" key="deviceTypeName" prop="deviceTypeName" fixed/>

                    <el-table-column label="车牌号" align="center" key="plateNo" prop="plateNo" />
                    <el-table-column label="VIN码" align="center" key="vin" prop="vin" />
                    <el-table-column label="设备供应商" align="center" key="deviceSupplierName" prop="deviceSupplierName"/>
                    <el-table-column label="流量类型" align="center" key="internetTrafficType" prop="internetTrafficType" />
                    <el-table-column label="流量大小" align="center" key="internetTraffic" prop="internetTraffic">
                        <template slot-scope="scope">
                            {{ scope.row.internetTraffic }}M
                        </template>
                    </el-table-column>
                    <el-table-column label="采购时间" align="center" key="purchaseTime" prop="purchaseTime" />
                    <el-table-column label="厂家发货时间" align="center" key="supplierSendTime" prop="supplierSendTime" />

                    <el-table-column label="库存状态" align="center" key="stockStatus" prop="stockStatus">
                        <template slot-scope="scope">
                            {{ scope.row.stockStatus == 0 ? '入库' : '出库' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="入库时间" align="center" key="inStorageTime" prop="inStorageTime" />
                    <el-table-column label="出库时间" align="center" key="outStorageTime" prop="outStorageTime" />
                    <el-table-column label="设备首次激活时间" align="center" key="activateTime" prop="activateTime" />
                    <el-table-column label="绑定车辆时间" align="center" key="bindTime" prop="bindTime" />
                    <el-table-column label="续费状态" align="center" key="renewStatus" prop="renewStatusName" />
                    <el-table-column label="续费时间" align="center" key="renewTime" prop="renewTime" />
                    <el-table-column label="厂商过期时间" align="center" key="supplierExpirationTime" prop="supplierExpirationTime" />
                    <el-table-column label="客户过期时间" align="center" key="tenantExpirationTime" prop="tenantExpirationTime" />
                    <el-table-column label="客户分公司" align="center" key="subTenantName" prop="subTenantName" />
                    <el-table-column label="客户总公司" align="center" key="rootTenantName" prop="rootTenantName" />
                    <el-table-column label="项目状态" align="center" key="projectStatus" prop="projectStatus" />
                    <el-table-column label="设备状态" align="center" key="deviceStatusName" prop="deviceStatusName" />
                    <el-table-column label="设备最后一次解绑时间" align="center" key="lastUnBindTime" prop="lastUnBindTime" />
                    <el-table-column label="设备上一次绑定车牌号" align="center" key="lastPlateNo" prop="lastPlateNo" />
                    <el-table-column label="设备在线状态" align="center" key="onlineStatus" prop="onlineStatus" >
                       <template slot-scope="scope">
                         {{ scope.row.onlineStatus === 1 ? '在线' :
                          (scope.row.onlineStatus === 2 ? '离线' :
                              (scope.row.onlineStatus === 0 ||scope.row.onlineStatus === null ? '离线' : '未知状态')) }}

                       </template>
                    </el-table-column>
                  <el-table-column label="设备插拔状态" align="center" key="pluggingStatus" prop="pluggingStatus" >
                    <template slot-scope="scope">
                      {{ scope.row.pluggingStatus === 1 ? '插入' :
                        (scope.row.pluggingStatus === 2 ? '拔出' :
                            (scope.row.pluggingStatus === 0 ? ' ' : '未知状态')) }}

                    </template>
                  </el-table-column>
                  <el-table-column label="设备上一次插入时间" align="center" key="lastInsertTime" prop="lastInsertTime" />
                  <el-table-column label="设备上一次拔出时间" align="center" key="lastPullTime" prop="lastPullTime" />
                  <el-table-column label="最后一次行驶时间" align="center" key="lastTripTime" prop="lastTripTime" />
                  <el-table-column label="操作" align="center" key="operate" prop="operate" fixed="right" width="100px">
                      <template slot-scope="scope">
                          <el-button 
                              style="margin-bottom: 5px;" 
                              @click="editRecord(scope.row)" 
                              type="primary"
                          >
                          编辑
                          </el-button>
                          <template v-if="scope.row.deviceType == 1">
                            <br/>
                            <el-button 
                              style="margin-bottom: 5px;" 
                              @click="openDualCameraSetting(scope.row)" 
                              type="primary"
                            >
                            设置
                            </el-button>
                          </template>
                          
                      </template>
                  </el-table-column>

                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchRecordList" />
            </div>
        
            <!-- 添加弹窗 -->
            <el-dialog :modal-append-to-body="false" title="变更记录" :visible.sync="recordFormVisible" width="800px" @close="doEditRecord(false)">
                <el-form :model="recordForm" ref="recordForm" size="small" label-width="100px" :rules="rules">
                    <el-form-item label="设备号" prop="deviceNumber">
                        <el-input v-model="recordForm.deviceNumber" maxlength="15" placeholder="请输入设备号" clearable />
                    </el-form-item>
                    <el-form-item label="设备类型" prop="deviceType">
                        <el-select style="width: 100%" v-model="recordForm.deviceType" placeholder="请选择设备类型">
                            <el-option label="OBD设备" value="0" key="0"></el-option>
                            <el-option label="双摄设备" value="1" key="1" ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="SIM卡号" prop="sim">
                        <el-input v-model="recordForm.sim" maxlength="15" placeholder="请输入SIM卡号" clearable />
                    </el-form-item>
                    <el-form-item label="ICCID" prop="iccid">
                        <el-input v-model="recordForm.iccid" maxlength="15" placeholder="请输入ICCID" clearable />
                    </el-form-item>
                    <el-form-item label="车牌号" prop="plateNo">
                        <el-input v-model="recordForm.plateNo" maxlength="8" placeholder="请输入车牌号" clearable />
                    </el-form-item>
                    <el-form-item label="车架号" prop="vin">
                        <el-input v-model="recordForm.vin" maxlength="17" placeholder="请输入VIN码" clearable />
                    </el-form-item>
                    <el-form-item label="设备型号" prop="deviceModule">
                        <el-input v-model="recordForm.deviceModule" maxlength="17" placeholder="请输入设备型号" clearable />
                    </el-form-item>
                    <el-form-item label="设备供应商" prop="deviceSupplierName">
                        <el-select style="width: 100%" v-model="recordForm.deviceSupplierName" placeholder="请选择设备供应商">
                            <el-option label="深圳市博实结科技有限公司" value="深圳市博实结科技有限公司" key="深圳市博实结科技有限公司"></el-option>
                            <el-option label="深圳市赛格导航科技股份有限公司" value="深圳市赛格导航科技股份有限公司" key="深圳市赛格导航科技股份有限公司" ></el-option>
                            <el-option label="北京自航世纪科技公司" value="北京自航世纪科技公司" key="北京自航世纪科技公司" ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="流量类型" prop="internetTrafficType">
                        <el-input v-model="recordForm.internetTrafficType" maxlength="17" placeholder="请输入流量类型" clearable />
                    </el-form-item>
                    <el-form-item label="流量大小" prop="internetTraffic">
                        <el-input v-model="recordForm.internetTraffic" maxlength="17" placeholder="请输入流量大小" clearable />
                    </el-form-item>
                    <el-form-item label="库存状态" prop="stockStatus">
                        <el-select style="width: 100%" v-model="recordForm.stockStatus" placeholder="请选择变更状态">
                            <el-option label="入库" value="0" key="0"></el-option>
                            <el-option label="出库" value="1" key="1" ></el-option>
                        </el-select>
                    </el-form-item>
           
                    <el-form-item label="采购时间" prop="purchaseTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.purchaseTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择采购时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="厂家发货时间" prop="supplierSendTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.supplierSendTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择厂家发货时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="入库时间" prop="inStorageTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.inStorageTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择入库时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="出库时间" prop="outStorageTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.outStorageTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择出库时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="设备激活时间" prop="activateTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.activateTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择设备激活时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="绑定车辆时间" prop="bindTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.bindTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择绑定车辆时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="续费时间" prop="renewTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.renewTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择续费时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="厂商过期时间" prop="supplierExpirationTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.supplierExpirationTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择厂商过期时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="租户过期时间" prop="tenantExpirationTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.tenantExpirationTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择租户过期时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="库管" prop="warehouseOperator">
                        <el-input v-model="recordForm.warehouseOperator" maxlength="17" placeholder="请输入库管" clearable />
                    </el-form-item>
                    <el-form-item label="质检" prop="qualityInspector">
                        <el-input v-model="recordForm.qualityInspector" maxlength="17" placeholder="请输入质检" clearable />
                    </el-form-item>
                    <el-form-item label="业务操作员" prop="businessOperator">
                        <el-input v-model="recordForm.businessOperator" maxlength="17" placeholder="请输入业务操作员" clearable />
                    </el-form-item>
                    <el-form-item label="客户总公司" prop="rootTenantId">
                        <el-select v-model="recordForm.rootTenantId" placeholder="请选择客户总公司" @change="changeTenant" clearable>
                            <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户分公司" prop="subTenantId">
                        <el-select style="width: 100%" v-model="recordForm.subTenantId" placeholder="请选择客户分公司" @change="changeCompany" clearable> 
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户车队" prop="fleetId">
                        <el-select style="width: 100%" v-model="recordForm.fleetId" placeholder="请选择客户车队" clearable>
                            <el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
          
                    <el-form-item label="项目状态" prop="projectStatus">
                        <el-input v-model="recordForm.projectStatus" placeholder="请输入项目状态" clearable />
                    </el-form-item>
                    <el-form-item label="设备状态" prop="deviceStatus">
                        <el-input v-model="recordForm.deviceStatus" placeholder="请输入设备状态" clearable />
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="recordForm.remark" placeholder="请输入备注" clearable />
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="doEditRecord(false)">取 消</el-button>
                    <el-button type="primary" @click="doEditRecord(true)">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 添加批次对话框 -->
            <el-dialog :title="title" :visible.sync="uploadRecordsVisible" width="600px" append-to-body>
                <el-form ref="uploadForm" :model="uploadForm" label-width="120px">
                    <el-form-item label="设备Excel" prop="file">
                        <el-upload class="upload-demo" ref="upload" :file-list="fileList" :on-change="handleFileChange" :on-remove="handleRemove" :disabled="loading" :auto-upload="false">
                            <el-button size="small" type="primary" :disabled="loading">选择文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="beforeUpload(file)" :disabled="loading">确 定</el-button>
                    <el-button @click="uploadRecordsVisible = false">取 消</el-button>
                </div>
            </el-dialog>
            <el-dialog :modal-append-to-body="false" title="双摄设备配置详情" :visible.sync="deviceConfigsVisible" width="1000px" @close="closeDualCameraSetting()">
                <div style="text-align: right; height: 40px">
                    <el-button @click="uploadConfigFile" type="primary" icon="el-icon-upload2" size="mini" :disabled="uploadConfigFileLoading">上传当前配置为通用配置</el-button>
                    <el-button @click="opendSendConfig" type="primary" icon="el-icon-upload2" size="mini">下发配置文件</el-button>

                </div>
                <el-table :data="dualCameraConfigRecordList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" fixed />
                    <el-table-column label="配置版本" align="center" key="version" prop="version" />
                    <el-table-column label="更新日期" align="center" key="updateTime" prop="updateTime"  />
                    <el-table-column label="下发日期" align="center" key="sendTime" prop="sendTime" />
                    <el-table-column label="状态" align="center" key="configStatus" prop="configStatus" >
                        <template slot-scope="scope">
                            {{ scope.row.configStatus === 1 ? '已启用' : '未启用' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="是否下发设备" align="center" key="sendStatus" prop="sendStatus" >
                        <template slot-scope="scope">
                            {{ scope.row.sendStatus === 1 ? '已下发' : '未下发' }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog :modal-append-to-body="false" title="双摄设备配置" :visible.sync="paramsSettingVisible" width="1000px" @close="closeSendConfig()">
       
                <el-table :data="dualCameraConfigFileList">
                    <el-table-column label="文件来源设备号" align="center" key="deviceNumber" prop="deviceNumber" />

                    <el-table-column label="配置版本" align="center" key="version" prop="version" />
                    <el-table-column label="更新日期" align="center" key="updateTime" prop="updateTime"  />
                    <el-table-column label="操作" align="center" key="operate" prop="operate" fixed="right" width="100px">
                      <template slot-scope="scope">
                        <el-button v-if="dualCamera.deviceNumber" style="margin-bottom: 5px;" @click="sendConfigFile(scope.row)" type="primary">
                            下发设备
                        </el-button>
                        <el-button v-else style="margin-bottom: 5px;" @click="sendConfigFileBatch(scope.row)" type="primary">
                            批量下发
                        </el-button>
                      </template>
                  </el-table-column>
                </el-table>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>

export default {
    name: 'bindManager',
    data() {
        return {
            title: '添加',
            loading: false,
            recordFormVisible: false,
            uploadRecordsVisible: false,
            deviceConfigsVisible: false,
            paramsSettingVisible: false,
            tenants: [
                { label: '环球车享', value: 10004 },
                { label: '太保商用车', value: 10049 }
            ],
            rootTenantId: null,
            subTenantId: null,
            companyList: [],
            fleetList: [],
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },

            recordList: [],
            total: 0,
            recordForm: {
            },
            uploadForm: {},
            fileList: [],
            rules: {
                deviceNumber: [
                    { required: true, message: '设备号不能为空', trigger: 'blur' },
                    { min: 10, max: 15, message: '设备号长度在 10 到 15 位', trigger: 'blur' }
                ]
            },
            baseURL: '',
            dualCamera: {},
            uploadConfigFileLoading: false,
            dualCameraConfigRecordList: [],
            dualCameraConfigFileList:[],
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        //this.fetchCompanyList();
        this.fetchRecordList();
    },
    methods: {
        sendConfigFileBatch(configFile) {
            const version = configFile.version;
            this.$prompt('请输入要下发的设备号，多个设备已逗号分隔', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                const data = {
                    deviceNumber: value,
                    value: version,
                }
                this.instance.post('/operate/api/dualCamera/sendDeviceConfigBatch', data)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success("配置" + version + "已成功下发");
                            this.closeSendConfig();
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((res) => {
                        this.$message.error('请求失败! 请稍后重试');
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        sendConfigFile(configFile) {
            const deviceNumber = this.dualCamera.deviceNumber;
            const version = configFile.version;
            const data = {
                deviceNumber: deviceNumber,
                value: version,
            }
            this.$confirm(`是否要将版本为${version}的配置文件下发设备${deviceNumber}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.instance.post('/operate/api/dualCamera/sendDeviceConfig', data)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success("配置" + version + "已成功下发设备" + deviceNumber);
                            this.closeSendConfig();
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((res) => {
                        this.$message.error('请求失败! 请稍后重试');
                    });
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
            
        },
        closeSendConfig() {
            this.paramsSettingVisible = false;
            if(this.dualCamera.deviceNumber) {
                this.fetchDualCameraConfigRecords(this.dualCamera.deviceNumber);
            }
        },
        opendSendConfig() {
            this.paramsSettingVisible = true;
            this.fetchDualCameraConfigFiles();
        },
        fetchDualCameraConfigRecords(deviceNumber) {
            this.instance.get('/operate/api/dualCamera/deviceConfigs?deviceNumber=' + deviceNumber)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.dualCameraConfigRecordList = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((res) => {
                    this.$message.error('请求失败! 请稍后重试');
                });
        },
        fetchDualCameraConfigFiles() {
            this.instance.get('/operate/api/dualCamera/configFiles')
                .then((res) => {
                    if (res.data.code === 200) {
                        this.dualCameraConfigFileList = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((res) => {
                    this.$message.error('请求失败! 请稍后重试');
                });
        },
        uploadConfigFile() {
        
            const data = {
                deviceNumber: this.dualCamera.deviceNumber,
            }
            this.uploadConfigFileLoading = true;
            this.instance.post('/operate/api/dualCamera/uploadConfigFile', data)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$message.success("上传成功, 文件版本号" + res.data.data.value);
                        this.fetchDualCameraConfigRecords(this.dualCamera.deviceNumber);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.uploadConfigFileLoading = false;
                })
                .catch((res) => {
                    this.uploadConfigFileLoading = false;
                    this.$message.error('请求失败! 请稍后重试');
                });
        },
        openDualCameraSetting(device) {
            this.dualCamera = device;
            this.deviceConfigsVisible = true;
            this.fetchDualCameraConfigRecords(device.deviceNumber);
        },
        closeDualCameraSetting() {
            this.deviceConfigsVisible = false;
            this.dualCamera = {};
        },

        downloadTemplate() {},
        uploadRecords() {
            this.fileList = [];
            this.file = null;
            this.uploadRecordsVisible = true;
        },

        handleFileChange(file, fileList) {
            this.file = file.raw;
            this.fileList = [this.file];
        },
        handleRemove(file, fileList) {
            this.file = undefined;
            this.fileList = [];
        },
        beforeUpload(file) {
            //this.disabled = true;
            let _this = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                _this.fileString = reader.result.replace('data:', '').replace(/^.+,/, '');
                _this.doUploadRecord(_this.fileString);
            };
            //this.disabled = false;
            return false;
        },

        doUploadRecord(fileContent) {
            this.loading = true;
            const data = {
                file: fileContent,
                rootTenantId: this.rootTenantId
            };
            this.instance
                .post('/operate/api/device/addStockRecordBatch', data)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code === 200) {
                        this.$message.success('上传成功');
                        this.resetWithLoadRecord();
                        this.uploadRecordsVisible = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    this.$message.error('请求失败! 请稍后重试');
                });
        },
    
        addRecord() {
            this.recordFormVisible = true;
            this.$refs['recordForm'].resetFields();
            this.recordForm = {};
        },
        editRecord(row) {
            this.recordFormVisible = true;
            if(this.$refs['recordForm']) {
                this.$refs['recordForm'].resetFields();
            }
            this.recordForm = {
                ...row
            };
        },
        doEditRecord(submitFlag) {
            if (submitFlag) {
                this.$refs['recordForm'].validate((valid) => {
                    if (valid) {
                        this.submitRecord();
                    }
                });
            } else {
                this.$refs.recordForm && this.$refs.recordForm.resetFields();
                this.recordFormVisible = false;
            }
        },
        submitRecord() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.recordForm));
            this.instance.post('/operate/api/device/stock/save', data).then((res) => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.recordFormVisible = false;
                    this.$message.success('提交成功!');
                    this.resetWithLoadRecord();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        submitDownloadTask() {
            var queryJson = JSON.parse(JSON.stringify(this.queryParams.condition));
            
            this.instance.post('/operate/api/device/submitDownloadStockRecordsExcel', queryJson).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.data);
                }
            });
        },
        downloadTable() {
            window.open(this.baseURL + '/operate/api/device/downloadStockRecordsExcel', '_blank');
        },

        changeTenant(rootTenantId) {
            this.rootTenantId = rootTenantId;
            this.fetchCompanyList();
        },
        changeCompany(subTenantId) {
            this.subTenantId = subTenantId;
            this.fetchFleetList();
        },
        fetchCompanyList() {
            this.instance.get('/operate/api/tenant/companyList?rootTenantId=' + this.rootTenantId).then((res) => {
                this.companyList = res.data.data;
            });
        },
        fetchFleetList() {
            var url = `/operate/api/tenant/fleetList?companyId=${this.subTenantId}&rootTenantId=${this.rootTenantId}`
            this.instance.get(url).then((res) => {
                this.fleetList = res.data.data;
            });
        },
        fetchRecordList() {
            this.loading = true;
            if(this.queryParams.condition.renewStatus=='') {
              this.queryParams.condition.renewStatus=null;
            }
            let data = JSON.parse(JSON.stringify(this.queryParams));
            
            this.instance.post('/operate/api/device/stockRecords', data).then((res) => {
                this.loading = false;
                this.recordList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        search(searchText) {
            this.reset();
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    search: searchText
                }
            };
            this.fetchBindRecordList();
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.fetchRecordList();
        },
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            };
        },
        reset() {
            this.resetQuery();
            this.bindRecordList = [];
            this.fileList = [];
            this.file = undefined;
            this.recordForm = {};
        },
        resetWithLoadRecord() {
            this.reset();
            this.fetchRecordList();
        }
    }
};
</script>